import API from 'store/api';
import analytics from 'utils/analytics';
 
export const getQuizListApi = async (_request) => {
 // let url = `/api/v1/assignment/student/${_request.userId}/request/template?skillId=${_request.skillId}&classroomId=${_request.classroomId}`;
 let url = `/api/v1/assignment/student/${_request.userId}/request/template?skillId=${_request.skillId}`;
  try {
    const response = await API.get(url);
    analytics.logEvent('get_quiz_list', { userId: _request.userId });
    return response.data;
  } catch (err) {
    analytics.logEvent('get_quiz_list_error', { error: err });
    throw err;
  }
};
