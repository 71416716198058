import React from 'react';
import cn from 'utils/cn';

export default function OnboardingCard({ image = '', title = '', className }) {
  return (
    <div
      className={cn(
        'py-8 grid h-full px-4 transition-all duration-100 ease-in-out',
        className,
      )}
    >
      <div className="rounded-[2rem] bg-white shadow-xl overflow-hidden grid grid-rows-[1fr,auto] h-full w-full">
        <div className="w-full h-[25vh] sm:h-[28rem] lg:h-[30vh] xl:h-[40vh] overflow-hidden">
          <img
            src={image}
            alt={title}
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div className="px-4 flex justify-center items-center py-6">
          <h1 className="text-center font-semibold text-base sm:text-xl xl:text-3xl 2xl:text-4xl">
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
}
