import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid } from '@mui/material';
import { getUserData } from 'store/Thunk/userThunk';
import { useDispatch } from 'react-redux';

function QuizScore(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let {
    title,
    name,
    totalMarks,
    totalGems,
    successPopup,
    failedPopup,
    resetPage,
    showSecondFail,
    answeredCount,
    showScore,
    handleSuccessPopup,
    failed2Popup,
    handleYesBtn,
    remainingQuiz,
    quizStat,
  } = props;

  const percentage = Math.round((answeredCount / totalMarks) * 100);
useEffect(()=>{
  console.log("quizStat", quizStat)
},[])
  return (
    <div
    className='flex-none lg:flex w-full rounded-md items-start text-center p-4 m-4 '
  
    >
     
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          width: 'fit-content',
          alignSelf: 'center',
          borderRadius: '16px',
          backgroundColor: '#F8F8F8',
          m: 1,
          p: 1,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}

          sx={{ height: '35ch' }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Box>
              <div


        className="!w-full !text-center text-[#000] font-bold text-[16px] lg:text-[25px]"


      >
        {title}
      </div>
      <div className='text-[#000] lg:text-[1.85rem] text-[16px] font-bold'>
      You scored
                  <span
                  className='text-[#C73E9D] ml-1 text-[16px] lg:text-[25px]'
                   
                  >
                    {quizStat?.TotalScoreForTheQuiz}%
                  </span>{' '}
                  on
      </div>
        
              </Box>
              <Box>
                <div className='lg:text-[1.85rem] text-[16px] text-[#000] font-bold'>
                <span
                className='lg:text-[1.85rem] text-[20px]'
                
                  >
                    "{title}"
                  </span>
                </div>
            
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <div className='text-[#000] font-bold lg:text-[1.85rem] text-[16px]'>
                You got{' '}
                  {quizStat?.TotalNumberOfCorrectlyAnsweredPerQuiz || ''} out of{' '}
                  {quizStat?.TotalNumberOfCorrectlyAnsweredPerQuiz +
                    quizStat?.TotalNumberOfIncorrectlyAnsweredPerQuiz ||
                    ''}{' '}
                  questions correct
                </div>
          
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <div className='text-[#000] font-bold lg:text-[1.85rem] text-[16px]'>
                You earned
                  <span
                  className='text-[#C73E9D] ml-1 lg:text-[1.85rem] text-[16px]'
                    
                  >
                    {`${quizStat?.TotalTokensEarnedPerQuiz} $G3MS`}
                  </span>
                </div>
              
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                {!quizStat?.IsReviewQuestionsAvailablePerQuiz ? (
                  <div className='text-[#000] font-bold lg:text-[1.85rem] text-[16px]'>
                      You have more assignments to complete. Will you like to
                      continue to next quiz?
                    </ div>
              
                ) : (
                  <div className='text-[#000] font-bold lg:text-[1.85rem] text-[16px]'>
                 Let's go back and review this lesson, then you can crush
                 those questions you got wrong?
                </ div>
              
                )}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', gap: '20px' }}>
            <Button
              sx={{
                textTransform: 'none',
                width: '100%',
                background: '#C73E9D',
                color: '#FFFFFF',
                boxShadow: 3,
                mt: 5,
                '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
              }}
              fullWidth
              onClick={
                !quizStat?.IsReviewQuestionsAvailablePerQuiz
                  ? handleSuccessPopup
                  : handleYesBtn
              }
            >
              {`${
                !quizStat?.IsReviewQuestionsAvailablePerQuiz
                  ? 'Yes I will continue'
                  : 'Yes let’s do it!'
              } `}
            </Button>
            <Button
              sx={{
                textTransform: 'none',
                width: '100%',
                background: '#D9E4E2',
                color: '#000',
                boxShadow: 3,
                mt: 5,
                '&.MuiButtonBase-root:hover': { backgroundColor: '#D9E4E2' },
              }}
              fullWidth
              // onClick={() => resetPage()}
              onClick={() => navigate('/app/student')}
            >
              Next Time
            </Button>
          </Box>

          {/* <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ color: '#000', fontWeight: '700' }}
              >
                Nice Try, You have scored {finalMarks}/{totalMarks * 10}
              </Typography>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </div>
  );
}

export default QuizScore;
