import { Modal } from '@mantine/core';
import { CameraAltRounded } from '@mui/icons-material';
import LoadingOverlay from 'app/components/LoadingOverlay';
import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import React, { useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
import { useSelector } from 'react-redux';
import {
    useGetMyProfileQuery,
    useUpdateProfileImageMutation,
} from 'store/apis/profile';
import { selectAccessToken } from 'store/Slice/authSlice';
import analytics from 'utils/analytics';
import cn from 'utils/cn';

function UploadAvatar() {
  const { openModal } = useModal();
  const camera = useRef(null);
  const videoRef = useRef(null);

  const imageInputRef = useRef(null);

  const { data: myProfileData } = useGetMyProfileQuery();

  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const [updateProfileImage, updateProfileImageResult] =
    useUpdateProfileImageMutation();

  const [showCamera, setShowCamera] = useState(false);
  const { checkProfileAndNavigate } = useFirebaseLogin();
  const accessToken = useSelector(selectAccessToken);

  const updateProfileImageHandler = async () => {
    try {
      if (!selectedAvatar) {
        openModal('WarningModal', {
          title: 'Error',
          message: 'Please select an avatar',
        });

        return;
      }

      const formData = new FormData();
      formData.append('profileImage', selectedAvatar);

      const res = await updateProfileImage(formData).unwrap();

      analytics.logEvent('profile_image_updated', {
        userId: res.data.id,
        profileImage: res.data.profileImage,
      });

      const user = myProfileData?.data;

      // if (
      //   user.type === 'private_tutor' ||
      //   user.type === 'teacher' ||
      //   user.type === 'parent'
      // ) {
      //   openModal('ParentPaymentWarning');
      //   return;
      // }

      // if (user.type !== 'student') {
      //   openModal('TeacherPaymentWarning');
      //   return;
      // }

      // openModal('StudentPaymentWarning');

      checkProfileAndNavigate(user, accessToken);
    } catch (err) {
      openModal('WarningModal', {
        title: 'Error',
        message: err.data?.message || 'An error occurred',
      });
    }
  };

  return (
    <section className="bg-[#D9E4E2] grid place-items-center min-h-[100dvh]">
      <div className="bg-white w-full max-w-[40rem] mx-auto p-5 rounded-lg gap-10 grid place-items-center h-full max-h-[45rem]">
        <Logo />
        <div className="grid place-items-center gap-5">
          <img
            src={
              selectedAvatar
                ? URL.createObjectURL(selectedAvatar)
                : '/images/common2.png'
            }
            className="size-24 lg:size-32 object-cover rounded-full xl:size-48 mx-auto my-4"
            alt={'avatar'}
          />

          <h1
            className={cn(
              'font-bold text-center text-lg lg:text-2xl xl:text-3xl text-[#131515]',
            )}
          >
            Your Profile Picture
          </h1>
          <p className="text-center text-sm lg:text-base xl:text-lg 2xl:text-xl text-[#979C9E]">
            Upload or Take a profile picture.
          </p>
        </div>
        <input
          type="file"
          ref={imageInputRef}
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            setSelectedAvatar(e.target.files[0]);
          }}
        />
        <div className="space-y-5 w-full">
          {selectedAvatar ? (
            <>
              <Button
                onClick={() => {
                  setSelectedAvatar(null);
                }}
                isLoading={updateProfileImageResult.isLoading}
                className="max-w-[25rem] mx-auto"
              >
                Retake
              </Button>
              <Button
                onClick={updateProfileImageHandler}
                variant="primary"
                className="max-w-[25rem] mx-auto"
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              {' '}
              <Button
                onClick={() => {
                  imageInputRef.current.click();
                }}
                isLoading={updateProfileImageResult.isLoading}
                className="max-w-[25rem] mx-auto"
              >
                Upload a photo
              </Button>
              <Button
                onClick={() => {
                  setShowCamera(true);
                }}
                isLoading={updateProfileImageResult.isLoading}
                className="max-w-[25rem] mx-auto"
                variant={'primary'}
              >
                Take a Picture
              </Button>
            </>
          )}
        </div>
        <LoadingOverlay isLoading={updateProfileImageResult.isLoading} />
      </div>
      <Modal
        opened={showCamera}
        onClose={() => setShowCamera(false)}
        size={'xl'}
        title={'Take a Picture'}
        centered
      >
        <div className="min-h-[clamp(20rem,50vh,30rem)] flex flex-col items-center justify-center gap-5">
          <Camera ref={camera} />
          <button
            onClick={async () => {
              await navigator.requestMediaKeySystemAccess();
              const url = camera.current.takePhoto();
              fetch(url)
                .then((r) => r.blob())
                .then((blobFile) => {
                  setSelectedAvatar(blobFile);
                  setShowCamera(false);
                });
              // setSelectedAvatar(camera.current.takePhoto());
              setShowCamera(false);
            }}
            className="bg-white text-black  absolute bottom-8 size-12 lg:size-16 xl:size-20 rounded-full grid place-items-center active:bg-[#F1F1F1] focus:bg-white/50"
          >
            <CameraAltRounded className="!text-2xl lg:!text-4xl xl:!text-5xl" />
          </button>
        </div>
      </Modal>
    </section>
  );
}

export default UploadAvatar;
