import React, { useId } from 'react';
import 'react-phone-number-input/style.css';

import PhoneInputWithCountrySelect, {
  getCountryCallingCode,
} from 'react-phone-number-input';
import cn from 'utils/cn';

export default function TelephoneInput({
  label,
  type = 'text',
  placeholder = 'Enter phone number',
  value,
  onChange,
  required = false,
  disabled = false,
  className = '',
  error,
  onCountryChange,
  defaultCountry = 'US',
  ...props
}) {
  const id = useId();

  return (
    <fieldset className="space-y-2 appearance-none">
      {label && (
        <label
          htmlFor={id}
          className="block font-medium text-[#484848] text-xs sm:text-sm lg:text-base xl:text-base"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <PhoneInputWithCountrySelect
          id={id}
          placeholder={placeholder}
          value={value}
          defaultCountry={defaultCountry}
          onChange={onChange}
          onCountryChange={(country) => {
            if (country) {
              const callingCode = getCountryCallingCode(country);
              onCountryChange?.({ country, callingCode });
            }
          }}
          required={required}
          disabled={disabled}
          className={cn(
            ' w-full flex !min-h-14 px-3 py-2 xl:py-3 text-base xl:text-lg border focus:border [&_input]:!outline-none  border-[#DCDCDC] rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center',
            'focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
            { 'border-[#F41F52] focus:border-[#F41F52]': !!error },
            className,
          )}
          addInternationalOption
          limitMaxLength
          countrySelectProps={{
            className: 'appearance-none',
          }}
          {...props}
          international={false}
        />
      </div>

      {error && <p className="text-[#F41F52] text-xs sm:text-sm">{error}</p>}
    </fieldset>
  );
}
