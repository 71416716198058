import { Loader } from '@mantine/core';
import { auth } from 'api/firebase';
import Button from 'components/Button';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ClassLinkRedirect = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('toke');
  const { checkProfileAndNavigate } = useFirebaseLogin();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setIsLoading(true);
          const res = await auth.signInWithCustomToken(token);
          checkProfileAndNavigate(undefined, res.user.getIdToken());
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setError(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [token]);

  console.log('isLoading', isLoading, 'error', error);

  return (
    <div className="h-dvh w-dvw p-5">
      <div className="h-full w-full grid place-content-center gap-4 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col items-center">
          {token && isLoading && (
            <div className="flex flex-col items-center gap-4">
              <Loader size="xl" />
              <p className="text-center font-semibold text-2xl">
                Redirecting...
              </p>
            </div>
          )}

          {!isLoading && error && (
            <div className="flex flex-col items-center gap-4">
              <p className="text-red-500 text-center font-semibold text-4xl">
                Something went wrong
              </p>
              <p className="text-center font-medium text-xl max-w-[80%]">
                {error?.message}
              </p>
              <Button onClick={() => navigate('/login')} className="w-1/2 mt-5">
                Go back to home
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassLinkRedirect;
