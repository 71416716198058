import { Box, Stack, Typography } from '@mui/material';
import { auth } from 'api/firebase';
import LoadingOverlay from 'app/components/LoadingOverlay';
import Arrow from 'auth/components/Arrow-Left.png';
import QrBorder from 'auth/components/QrBorder.png';
import sharedStyles from 'auth/sharedStyle.module.scss';
import useModal from 'components/Modal/hook/useModal';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import { useLoginWithQrCodeMutation } from 'store/apis/auth';
import { logoutUser } from 'store/Slice/authSlice';
import { setTempUser } from 'store/Slice/uiSlice';
import { useAppDispatch } from 'store/store';

export default function QrLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const qrRef = React.useRef(null);

  const [loginWithQRCode, loginWithQRCodeRes] = useLoginWithQrCodeMutation();
  const { checkProfileAndNavigate } = useFirebaseLogin();
  const { openModal } = useModal();
  const queryParams = new URLSearchParams(window.location.search);
  const redirect = queryParams.get('redirect');

  const [info, setInfo] = useState({
    ipAddress: '',
    userAgent: '',
    macAddress: 'Unavailable in browser',
  });

  /**
   * @param {string} qrCode
   */
  const onReadSuccess = async (qrCode) => {
    try {
      auth?.signOut();
      dispatch(logoutUser());
      const res = await loginWithQRCode({
        qrCode,
        ipAddress: info?.ipAddress,
        macAddress: info?.macAddress,
        userAgent: info?.userAgent,
      }).unwrap();

      const customToken = res?.data?.customToken;
      const user = res?.data?.user;

      if (customToken && user) {
        await auth?.signInWithCustomToken(customToken);

        const accessToken = await auth?.currentUser?.getIdToken();

        // console.log('accessToken', accessToken)

        if (accessToken) {
          // setsubscriptionModalVisible(true);
          checkProfileAndNavigate(user, accessToken);
        } else {
          openModal('QrCodeErrorModal');
          // Toast.show({
          //   type: 'error',
          //   text1: 'Login Failed',
          //   text2: 'Failed to get access token.',
          //   visibilityTime: 2000,
          // });
        }
      } else {
        if (
          res?.data?.tempUser
          //  &&
          // res?.data?.tempUser.qrCodeType === 'REFERRAL'
        ) {
          dispatch(setTempUser(res?.data?.tempUser));
          openModal('QrCodeSuccessModal');
          if (redirect && redirect === 'plans') {
            navigate('/plans?promo=' + qrCode + '&showModal=true');
            return;
          }
          navigate('/birth');
        } else {
          openModal('QrCodeErrorModal');
        }

        // Toast.show({
        //   type: 'error',
        //   text1: 'Login Failed',
        //   text2: 'Failed to get custom token.',
        //   visibilityTime: 2000,
        // });
      }
    } catch (error) {
      openModal('QrCodeErrorModal');
      // const err = error as {
      //   data: TErrorResponse;
      // };
      // const message = Array.isArray(err?.data?.message)
      //   ? err?.data?.message[0]
      //   : err?.data?.message;

      // wm.show({
      //   title: message || 'Failed to get access token',
      // });

      // Toast.show({
      //   type: 'error',
      //   text1: 'Login Failed',
      //   text2: message || 'Failed to get access token.',
      //   visibilityTime: 2000,
      // });
    }
  };

  useEffect(() => {
    const userAgent = navigator?.userAgent;

    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        setInfo((prev) => ({
          ...prev,
          userAgent,
          ipAddress: data.ip,
        }));
      })
      .catch((error) => console.error('Error fetching IP address:', error));
  }, []);

  return (
    <Box className={sharedStyles.qrContainer}>
      <Box className={sharedStyles.arrowFloat}>
        <img
          src={Arrow}
          alt="img-discription"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(-1);
            // setStep((prevStep) => ({
            //   ...prevStep,
            //   current: 'home',
            // }));
          }}
        />
      </Box>
      <Box className={sharedStyles.avatar}>
        {/* <img src={QRCode} className={sharedStyles.qrCode} alt={'logo'} /> */}
        <video id="qr-video" className={sharedStyles.qrCode}></video>
        <img src={QrBorder} alt={'logo'} />
      </Box>
      <QrReader
        onResult={(result) => {
          if (result?.text) {
            onReadSuccess(result.text);
          }
        }}
        style={{ width: '100%' }}
        videoId="qr-video"
        videoStyle={{ width: '90%', height: '100%' }}
        constraints={{
          facingMode: 'environment',
          aspectRatio: { ideal: 1 },
        }}
        ref={qrRef}
      />

      <Stack sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}>
        <Typography
          className="!font-semibold"
          style={{ color: '#131515', fontSize: '32px' }}
        >
          Scan QR code to sign in
        </Typography>
      </Stack>

      <LoadingOverlay isLoading={loginWithQRCodeRes.isLoading} />
    </Box>
  );
}
