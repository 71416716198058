import React from 'react';

import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Input from 'components/Input';

import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  day: Yup.number().required('Required'),
  month: Yup.number().required('Required'),
  year: Yup.number().required('Required'),
});

export default function SelectBirthDate() {
  const { openModal } = useModal();
  const searchParams = new URLSearchParams(window.location.search);

  const phoneNumber = searchParams.get('phoneNumber');

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      day: '',
      month: '',
      year: '',
    },
    onSubmit: (values, { setFieldError }) => {
      const day = Number(values.day);
      const month = Number(values.month);
      const year = Number(values.year);

      const monthWith31Days = [1, 3, 5, 7, 8, 10, 12];

      if (day > 31) {
        setFieldError('day', 'Invalid Date, Please enter a valid date');
        // Toast.show({
        //   type: 'error',
        //   text1: 'Invalid Date',
        //   text2: 'Please enter a valid date',
        // });
        return;
      }

      if (day > 30 && !monthWith31Days.includes(month)) {
        setFieldError('day', 'Invalid Date, Please enter a valid date');
        // Toast.show({
        //   type: 'error',
        //   text1: 'Invalid Date',
        //   text2: 'Please enter a valid date',
        // });
        return;
      }

      if (month === 2 && day > 28) {
        const isLeapYear =
          Number(year) % 4 === 0 && Number(year) % 100 !== 0 ? true : false;

        if (Number(month) === 2 && Number(day) > 29 && isLeapYear) {
          setFieldError('day', 'Invalid Date, Please enter a valid date');
          // Toast.show({
          //   type: 'error',
          //   text1: 'Invalid Date',
          //   text2: 'Please enter a valid date',
          // });
          return;
        }

        if (Number(day) > 28 && !isLeapYear) {
          setFieldError('day', 'Invalid Date, Please enter a valid date');
          // Toast.show({
          //   type: 'error',
          //   text1: 'Invalid Date',
          //   text2: 'Please enter a valid date',
          // });
          return;
        }
      }

      const date = dayjs(new Date(year, month - 1, day));

      if (!date.isValid()) {
        setFieldError('day', 'Invalid Date, Please enter a valid date');
        // Toast.show({
        //   type: 'error',
        //   text1: 'Invalid Date',
        //   text2: 'Please enter a valid date',
        // });
        return;
      }

      console.log(date.format('YYYY-MM-DD'));

      const yearDifference = dayjs().diff(date, 'year', true);

      console.log(yearDifference);

      const under13 = yearDifference < 13;

      if (under13) {
        openModal('AskAGrownupModal', {
          birthDate: date.format('YYYY-MM-DD'),
        });
      } else {
        if (phoneNumber) {
          navigate(
            '/over13?phone=' +
              phoneNumber +
              '&birthDate=' +
              date.format('YYYY-MM-DD'),
          );
          return;
        }
        const url = `/phone-validation?${queryString.stringify({
          birthDate: date.format('YYYY-MM-DD'),
        })}`;
        navigate(url);
      }
    },
    validationSchema,
  });

  console.log(formik.values);

  return (
    <AuthPage className="gap-5">
      <Logo className="w-36 mb-14" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>What is your birth date?</AuthDetails.Title>
        <AuthDetails.Description>
          The G3MS learn to earn platform is designed to keep kids safe online
          while letting them learn anywhere on any device!
        </AuthDetails.Description>
      </AuthDetails>

      <form
        className="grid md:grid-cols-3 gap-6 w-full"
        onSubmit={formik.handleSubmit}
      >
        <Input
          label="Month"
          name="month"
          value={formik.values.month}
          onChange={(val) => formik.setFieldValue('month', val)}
          placeholder="MM"
          type="number"
          onBlur={formik.handleBlur}
          error={formik.errors.month}
        />

        <Input
          label="Day"
          name="day"
          value={formik.values.day}
          onChange={(val) => formik.setFieldValue('day', val)}
          placeholder="DD"
          type="number"
          onBlur={formik.handleBlur}
          error={formik.errors.day}
        />

        <Input
          label="Year"
          name="year"
          value={formik.values.year}
          onChange={(val) => formik.setFieldValue('year', val)}
          placeholder="YYYY"
          type="number"
          min="1900"
          max={dayjs().subtract(1, 'year').format('YYYY')}
          onBlur={formik.handleBlur}
          error={formik.errors.year}
        />

        <div className="col-span-3">
          <Button
            variant={'primary'}
            className={'max-w-[25rem] mt-4 mx-auto !w-full '}
          >
            Next
          </Button>
        </div>
      </form>
    </AuthPage>
  );
}
