import { LoadingOverlay, Portal } from '@mantine/core';
import React from 'react';

export default function LoadingOver({ isLoading }) {
  if (!isLoading) return null;

  return (
    <Portal zIndex={10}>
      <LoadingOverlay visible zIndex={999999999999999} />
    </Portal>
  );
}
