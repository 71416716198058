import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'utils/cn';

export default function ScanWithQrButton({ url = '/qr', className = '' }) {
  return (
    <Link
      to={url}
      className={cn(
        'shadow-2xl p-8 grid gap-8 place-items-center rounded-3xl w-full max-w-[20rem] mx-auto cursor-pointer',
        className,
      )}
    >
      <img src={'qr.svg'} alt="qr" className="size-32" />
      <h2 className="font-semibold text-xl xl:text-2xl text-center">Scan QR Code</h2>
    </Link>
  );
}
