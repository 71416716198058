import AccountCreatedModal from './AccountCreatedModal';
import AddSchoolModal from './AddSchoolModal';
import ApplyPromoModal from './ApplyPromoModal';
import AskAGrownupModal from './AskAGrownupModal';
import LeadersPaymentWarning from './LeadersPaymentWarning';
import InvalidOtpModal from './OtpErrorModal';
import OtpSuccessModal from './OtpSuccessModal';
import ParentPaymentWarning from './ParentPaymentWarning';
import PaymentDetails from './PaymentDetails';
import QrCodeErrorModal from './QrCodeErrorModal';
import QrCodeSuccessModal from './QrCodeSuccessModal';
import RequestParentApproval from './RequestParentApproval';
import StudentPaymentWarning from './StudentPaymentWarning';
import TeacherPaymentWarning from './TeacherPaymentWarning';
import WarningModal from './WarningModal';

const modals = {
  InvalidOtpModal,
  OtpSuccessModal,
  AccountCreatedModal,
  StudentPaymentWarning,
  TeacherPaymentWarning,
  ParentPaymentWarning,
  WarningModal,
  AskAGrownupModal,
  AddSchoolModal,
  RequestParentApproval,
  LeadersPaymentWarning,
  QrCodeSuccessModal,
  QrCodeErrorModal,
  PaymentDetails,
  ApplyPromoModal,
};

export default modals;
