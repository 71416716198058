import React from 'react';

import Button from 'components/Button';

import AuthDetails from 'components/AuthDetails';
import Input from 'components/Input';
import Logo from 'components/Logo';
import { useFormik } from 'formik';

import TelephoneInput from 'components/TelephoneInput';
import { isValidPhoneNumber } from 'libphonenumber-js/min';
import { useNavigate } from 'react-router-dom';
import { useCreatePaymentConfirmationMutation } from 'store/apis/auth';
import { useGetMyProfileQuery } from 'store/apis/profile';
import * as Yup from 'yup';
import useModal from 'components/Modal/hook/useModal';
import Modal from 'components/Modal/components/Modal';

const validationSchema = Yup.object({
  // parentEmail: Yup.string().email('Invalid email').required('Required'),
  parentFirstName: Yup.string().required('Parents First name is required'),
  parentLastName: Yup.string().required('Parents Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  dialCode: Yup.string().required('Country is required'),
});

export default function RequestParentApproval() {
  const { closeModal, isOpen, openModal } = useModal();

  const navigate = useNavigate();

  const { data, isLoading } = useGetMyProfileQuery();

  const [requestParentConfirmation, { isLoading: isRequestingParent }] =
    useCreatePaymentConfirmationMutation();

  const formik = useFormik({
    initialValues: {
      parentFirstName: '',
      parentLastName: '',
      phone: '',
      dialCode: '+1',
    },
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        if (!isValidPhoneNumber(values.phone)) {
          setFieldError('phone', 'Invalid phone number');
          return;
        }

        if (!data?.data) {
          return;
        }

        /* const checkPhoneRes = await checkIsPhoneAvailable({
          phone: values.phone,
        }).unwrap();

        if (checkPhoneRes.data.isPhoneNumberAvailable === false) {
          openModal('WarningModal', {
            title: 'Phone number already exists',
            description: 'This phone number is already registered with us.',
          });
          return;
        } */

        const res = await requestParentConfirmation({
          parentFirstName: values.parentFirstName,
          parentLastName: values.parentLastName,
          parentPhone: values.phone,
          userId: data?.data?.id,
        }).unwrap();

        console.log(res);

        closeModal();

        openModal('WarningModal', {
          title: 'Approval Sent! ✅',
          message:
            'We’ve sent a request to your parent to approve your upgrade. Ask them to check their text and confirm!',
          onContinue: () => {
            navigate('/app/student');
          },
          onClose: () => {
            navigate('/app/student');
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Modal isOpen={isOpen('RequestParentApproval')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_10%]">
        <Modal.CloseButton />

        <form
          className="flex flex-col gap-5 py-4"
          onSubmit={formik.handleSubmit}
        >
          <Logo className="mx-auto w-48 lg:w-56" />

          <AuthDetails className="mt-4">
            <AuthDetails.Title>Ask a Grownup?</AuthDetails.Title>
            <AuthDetails.Description className="mt-4">
              You can still join us, but before you start using the camera or
              claiming your rewards, we'll need a grownup's okay.
            </AuthDetails.Description>
          </AuthDetails>

          <div className="grid md:grid-cols-2 gap-5">
            <Input
              placeholder="Parent's First Name"
              error={
                formik.touched.parentFirstName && formik.errors.parentFirstName
              }
              label={'Type your parent first name'}
              {...formik.getFieldProps('parentFirstName')}
            />
            <Input
              placeholder="Parent's Last Name"
              error={
                formik.touched.parentLastName && formik.errors.parentLastName
              }
              label={'Type your parent last name'}
              {...formik.getFieldProps('parentLastName')}
            />
          </div>

          <TelephoneInput
            label="Phone Number"
            error={formik.touched.phone && formik.errors.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            onCountryChange={({ callingCode, country }) => {
              formik.setFieldValue(
                'dialCode',
                callingCode?.toString()?.includes('+')
                  ? callingCode
                  : `+${callingCode}`,
              );
              formik.setFieldValue('country', country);
            }}
          />

          <Button
            className="max-w-none w-full mt-4"
            variant="primary"
            type="submit"
            isLoading={isRequestingParent || isLoading}
          >
            Continue
          </Button>
        </form>
      </Modal.Content>
    </Modal>
  );
}
