import React from 'react';

import AuthDetails from 'components/AuthDetails';
import Button from 'components/Button';
import success from './assets/success.svg';
import useModal from 'components/Modal/hook/useModal';
import Modal from 'components/Modal/components/Modal';

export default function QrCodeSuccessModal() {
  const { closeModal, isOpen, modal } = useModal();

  return (
    <Modal isOpen={isOpen('QrCodeSuccessModal')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_30rem]">
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <img
            src={success}
            alt="success"
            className="size-8 md:size-10 lg:size-12 mx-auto"
            size="sm"
            mx={'auto'}
          />

          <AuthDetails.Title>🎉 QR Code Received! 🎉</AuthDetails.Title>

          <AuthDetails.Description>
            Thank you for scanning your QR code! We’ve successfully recognized
            your code. Now, please set up your account to start enjoying all the
            benefits of G3MS. Follow the steps to complete your registration,
            and unlock exciting features tailored just for you!
          </AuthDetails.Description>

          <Button
            className="max-w-none w-full"
            variant="primary"
            onClick={modal?.props?.onContinue}
          >
            Continue
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
