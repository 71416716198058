import React from 'react';
import cn from 'utils/cn';

export default function Button({
  children,
  className = '',
  onClick = () => {},
  variant,
  isLoading,
  component: Component = 'button',
  ...props
}) {
  return (
    <Component
      className={cn(
        'group flex items-center justify-center h-14 overflow-hidden border-[2px] border-[#C73E9D] text-[#C73E9D] px-3 py-2 2xl:py-3 cursor-pointer rounded-xl font-semibold hover:bg-[#C73E9D] hover:text-white transition-all duration-200 ease-in-out w-full max-w-[15rem]',
        'text-base',
        {
          'bg-[#C73E9D] text-white hover:bg-white hover:text-[#C73E9D]':
            variant === 'primary',
          'opacity-60 cursor-not-allowed': isLoading,
        },
        className,
      )}
      onClick={onClick}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <span className="spinner [--spinner-size:20px] group-hover:[--spinner-color:#C73E9D]"></span>
      ) : (
        children
      )}
    </Component>
  );
}
