import { apiSlice } from '..';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerChildUser: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/child/register',
          method: 'POST',
          body: body,
          headers: {
            Authorization: '',
          },
        };
      },
    }),
    registerOtherUser: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/user/register',
          method: 'POST',
          body: body,
          headers: {
            Authorization: '',
          },
        };
      },
    }),
    registerFirebaseUser: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/firebase/register',
          method: 'POST',
          body: body,
          headers: {
            Authorization: '',
          },
        };
      },
    }),
    loginWithUsername: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/login/username',
          method: 'POST',
          body: body,
          headers: {
            Authorization: '',
          },
        };
      },
    }),
    loginWithQrCode: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/scan/qrcode',
          method: 'POST',
          body: body,
          headers: {
            Authorization: '',
          },
        };
      },
    }),
    getAvatarList: builder.query({
      query: () => {
        return {
          url: 'user/avatars/fetch',
          method: 'GET',
        };
      },
    }),
    updateAvatarUnder13: builder.mutation({
      query: (body) => {
        return {
          url: '/user/avatar/update',
          method: 'POST',
          body: body,
        };
      },
    }),
    checkPhoneNumberExists: builder.mutation({
      query: (body) => {
        return {
          url: 'auth/check-phone-existence',
          method: 'POST',
          body: body,
        };
      },
    }),
    createPaymentConfirmation: builder.mutation({
      query: (body) => {
        return {
          url: 'auth/create-parent-by-student',
          method: 'POST',
          body: body,
        };
      },
    }),
    requestParentToApprove: builder.mutation({
      query: (body) => {
        return {
          url: 'auth/create-parent-child-confirmation',
          method: 'POST',
          body: body,
        };
      },
    }),
    classLinkLogin: builder.mutation({
      query: (body) => {
        return {
          url: '/users/login',
          method: 'POST',
          body: body,
        };
      },
    }),
    classLinkRegister: builder.mutation({
      query: (body) => {
        return {
          url: '/user',
          method: 'POST',
          body: body,
        };
      },
    }),
  }),
});

export const {
  useRegisterChildUserMutation,
  useRegisterFirebaseUserMutation,
  useLoginWithUsernameMutation,
  useRegisterOtherUserMutation,
  useGetAvatarListQuery,
  useUpdateAvatarUnder13Mutation,
  useLoginWithQrCodeMutation,
  useCheckPhoneNumberExistsMutation,
  useCreatePaymentConfirmationMutation,
  useRequestParentToApproveMutation,
  useClassLinkLoginMutation,
  useClassLinkRegisterMutation,
} = authApi;
