import {
  SettingsConsumer,
  SettingsProvider,
} from '@core/context/settingsContext';

import { getAnalytics, logEvent } from 'firebase/analytics';

import ThemeComponent from '@core/theme/ThemeComponent';
import ParentToApproveChildReq from 'app/payment/childUpgradeApproval';
import PaymentUnsuccessful from 'app/payment/paymentUnsuccessfull';
import VideoDetails from 'app/student/components/Layout/VideoChallenge/DailyLesson';
import DailyQuiz from 'app/student/components/Layout/dailyChallenge';
import ChallengeSelection from 'app/student/components/challengeSelection';
import DressChallenge from 'app/student/components/challenges/dress';
import SneakerChallenge from 'app/student/components/challenges/sneaker';
import StudentProfile from 'app/student/components/profile/profile';
import ParentChildApprovalSettings from 'pages/ChildApprovalSettings';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import 'react-notifications/lib/notifications.css';
import { Route, Routes } from 'react-router-dom';
import StudentAppModule from './app/student/app';
import './index.css';
import UserLayout from './layouts/UserLayout';
// ** React Perfect Scrollbar Style
import TopSquad from 'app/student/components/TopSquad/TopSquad';
import Login from 'auth/login/login_new';

import { useEffect } from 'react';

import { LoadingOverlay, Portal } from '@mantine/core';

import ModalWrapper from 'components/Modal/components/ModalWrapper';
import PrivateLayout from 'layouts/PrivateLayout';
import PublicLayout from 'layouts/PublicLayout';
import AiChat from 'pages/Aichat/AiChat';
import CompleteProfile from 'pages/CompleteProfile';
import EmailValidation from 'pages/EmailValidation';
import HomeScreen from 'pages/HomeScreen';
import LoginScreen from 'pages/LoginScreen';
import OnBoarding from 'pages/OnBoaring';
import OtpValidationScreen from 'pages/OtpValidationScreen';
import Over13SignupScreen from 'pages/Over13SignupScreen';
import PaymentConfirmation from 'pages/PaymentConfirmation';
import PaymentPlanList from 'pages/PaymentPlanList';
import PhoneNumberValidation from 'pages/PhoneNumberValidation';
import QrLogin from 'pages/QrLogin';
import SelectAvatar from 'pages/SelectAvatar';
import SelectBirthDate from 'pages/SelectBirthDate';
import ShareToEarn from 'pages/ShareToEarn/ShareToEarn';
import TokenLogin from 'pages/TokenLogin';
import Under13SignupScreen from 'pages/Under13SignupScreen';
import UploadAvatar from 'pages/UploadAvatar';
import WebRedirect from 'pages/WebRedirect';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'store/Slice/uiSlice';
import ClassLinkRedirect from 'pages/ClassLinkRedirect';
import Intercom from '@intercom/messenger-js-sdk';
import { selectUser } from 'store/Slice/authSlice';
import config from 'configs/config';
import ChildUpgradeApproval from 'pages/ChildUpgradeApproval';

function App(props) {
  const { Component } = props;
  const setConfig = Component?.setConfig ?? undefined;
  const isGlobalLoading = useSelector(selectIsLoading);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      Intercom({
        app_id: config.INTERCOM_APP_ID,
        email: user.email,
        user_id: user.id,
        name: user.name + ' ' + user.lastname,
        created_at: user.createdAt,
        hide_default_launcher: true,
      });
    }

    if (!user) {
      /*    Intercom({
        app_id: config.INTERCOM_APP_ID,
      }); */
      Intercom({
        app_id: config.INTERCOM_APP_ID,
        email: user?.email,
        user_id: user?.id,
        name: undefined,
        created_at: user?.createdAt,
        hide_default_launcher: true,
      });
    }
  }, [user]);

  useEffect(() => {
    const analytics = getAnalytics();
    const handlePathChangeAnalytics = () => {
      const path = window.location.pathname;
      logEvent(analytics, 'page_view', { page_path: path });
    };

    handlePathChangeAnalytics();

    window.addEventListener('popstate', handlePathChangeAnalytics);

    return () => {
      window.removeEventListener('popstate', handlePathChangeAnalytics);
    };
  }, []);

  return (
    <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
      <SettingsConsumer>
        {({ settings }) => {
          return (
            <ThemeComponent settings={settings}>
              <ModalWrapper>
                <NotificationContainer />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <PublicLayout>
                        <OnBoarding />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path="/home"
                    element={
                      <PublicLayout>
                        <HomeScreen />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path="/qr"
                    element={
                      <PublicLayout>
                        <QrLogin />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path="/birth"
                    element={
                      <PublicLayout>
                        <SelectBirthDate />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <PublicLayout>
                        <LoginScreen />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path="/under13"
                    element={
                      <PublicLayout>
                        <Under13SignupScreen />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path="/over13"
                    element={
                      <PublicLayout>
                        <Over13SignupScreen />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path="/phone-validation"
                    element={<PhoneNumberValidation />}
                  />
                  <Route
                    path="/app/hooks/classlink"
                    element={<ClassLinkRedirect />}
                  />
                  <Route
                    path="/email-validation"
                    element={<EmailValidation />}
                  />
                  <Route
                    path="/otp-validation"
                    element={<OtpValidationScreen />}
                  />
                  <Route
                    path="/complete-profile"
                    element={<CompleteProfile />}
                  />

                  <Route path="/plans" element={<PaymentPlanList />} />

                  <Route
                    path="/select-avatar"
                    element={
                      <PrivateLayout>
                        <SelectAvatar />
                      </PrivateLayout>
                    }
                  />
                  <Route
                    path="/upload-avatar"
                    element={
                      <PrivateLayout>
                        <UploadAvatar />
                      </PrivateLayout>
                    }
                  />
                  {/* <Route path="/auth/login" element={<Login />} />
                  <Route path="/auth/logout" element={<Logout />} />
                  <Route path="/auth/register/:type" element={<Register />} />
                  <Route path="/auth/register/" element={<Register />} />
                  <Route path="/auth/pricing/" element={<Pricing />} /> */}
                  <Route path="/auth/token-login" element={<TokenLogin />} />
                  {/* <Route
                    path="/auth/complete_profile/"
                    element={<CompleteProfile />}
                  />
                  <Route
                    path="/parent/complete_profile"
                    element={<ParentCompleteProfile />}
                  /> */}
                  {/* <Route
                    path="/plans"
                    element={<ParentUpgradePlan />}
                  /> */}
                  <Route
                    path="/payment/successful"
                    element={<PaymentConfirmation />}
                  />
                  <Route
                    path="/payment/unsuccessful"
                    element={<PaymentUnsuccessful />}
                  />
                  {/* <Route
                    path="/payment/upgrade_plan"
                    element={<StripePaymentMethodForm />}
                  /> */}
                  <Route
                    path="/parent/approve_child_settings"
                    element={<ChildUpgradeApproval />}
                  />
                  <Route
                    path="/parent/approve_child_request"
                    element={<ParentChildApprovalSettings />}
                  />
                  {/* <Route
                    path="/qr/successfully_recieved"
                    element={<QRCodeSuccessfullyRecieved />}
                  />
                  <Route
                    path="/qr/not_recieved"
                    element={<QRCodeNotRecieved />}
                  /> */}
                  {/* <Route
                    path="/auth/verification_successfull"
                    element={<EmailPhoneSuccessfullyVerified />}
                  /> */}
                  {/* <Route
                    path="/auth/verification_invalid"
                    element={<EmailPhoneVerificationInvalid />}
                  /> */}
                  {/* <Route path="/ask_grownup" element={<AskGrownUp />} /> */}
                  {/* <Route
                    path="/congrats_10_tokens"
                    element={<CongratsOnEarning10Tokens />}
                  /> */}
                  {/* <Route
                    path="/student/flow-complete-successfully"
                    element={<StudentFlowCompleteEarning50Tokens />}
                  /> */}
                  {/* <Route
                    path="/educator/flow-complete"
                    element={<EducatorFlowComplete />}
                  /> */}
                  <Route
                    path="/app/student"
                    element={
                      <UserLayout>
                        <StudentAppModule />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/profile"
                    element={
                      <UserLayout>
                        <StudentProfile />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/leaderboard"
                    element={
                      <UserLayout>
                        <TopSquad />
                      </UserLayout>
                    }
                  />
                  {/* <Route path="/app/teacher" element={<TeacherAppModule />} /> */}
                  <Route
                    path="/app/student/video"
                    element={
                      <UserLayout>
                        <VideoDetails />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/daily-quiz"
                    element={
                      <>
                        <DailyQuiz />
                      </>
                    }
                  />
                  <Route
                    path="/app/student/challenge-selection"
                    element={
                      <UserLayout>
                        <ChallengeSelection />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/dress-challenge/:id"
                    element={<DressChallenge />}
                  />
                  <Route
                    path="/app/student/sneaker-challenge/:id"
                    element={<SneakerChallenge />}
                  />
                  <Route
                    path="/redirectWeb"
                    element={
                      <PrivateLayout>
                        <WebRedirect />
                      </PrivateLayout>
                    }
                  />
                  <Route path="/" element={<Login />} />
                  <Route path="*" element={<Login />} />

                  <Route
                    path="/app/student/chat"
                    element={
                      <div className="p-4 h-[100dvh]">
                        <AiChat />
                      </div>
                    }
                  />
                  <Route
                    path="/app/student/share-to-earn"
                    element={
                      <UserLayout>
                        <ShareToEarn />
                      </UserLayout>
                    }
                  />
                </Routes>
              </ModalWrapper>
            </ThemeComponent>
          );
        }}
      </SettingsConsumer>
      <Portal>
        <LoadingOverlay visible={isGlobalLoading} />
        <button
          onClick={() => window.Intercom('show')}
          className="fixed bottom-0 right-0  text-white p-2 rounded-full h-[8rem] w-[8rem] overflow-hidden bg-cover z-[999999]"
          style={{
            backgroundImage: "url('/chatbot.svg')",
          }}
        />
      </Portal>
    </SettingsProvider>
  );
}

export default App;
