import React from 'react';

import LoadingOverlay from 'app/components/LoadingOverlay';
import Button from 'components/Button';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useRequestParentToApproveMutation } from 'store/apis/auth';
import { useGetMyProfileQuery } from 'store/apis/profile';
import getUserName from 'utils/getUserName';

import animation from 'asset/lottie/bot-animation.json';
import useModal from 'components/Modal/hook/useModal';
import { Modal } from '@mantine/core';
import CloseButton from 'components/Modal/components/CloseButton';

export default function StudentPaymentWarning() {
  const { closeModal, isOpen, openModal } = useModal();
  const { data, isLoading } = useGetMyProfileQuery();
  const [sendParentApproval, { isLoading: isRequestingParent }] =
    useRequestParentToApproveMutation();

  const navigate = useNavigate();

  const userName = React.useMemo(() => {
    if (data?.data) {
      return getUserName({
        firstName: data?.data?.name,
        lastName: data?.data?.lastname,
        username: data?.data?.username,
        type: data?.data?.type ?? 'student',
      });
    }
    return '';
  }, [data]);

  const onNext = React.useCallback(async () => {
    try {
      const user = data?.data;
      const isThereParent = user?.parent?.email;
      const isUnder13 = user?.isUnder13;
      const isParentVerified = user?.userDependent?.isRelationVerified;
      const parentApprovalStatus =
        user?.parentConfirmations?.parentConfirmationForChildSignup?.status;

      const isParentPhoneVerified = user?.parent?.isPhoneVerified;

      if (parentApprovalStatus === 'PENDING') {
        openModal('WarningModal', {
          title: 'Parent Approval Required',
          description:
            'Your parent approval request has already been sent. Please ask them to respond to the SMS text message to approve and upgrade.',
        });
        return;
      }

      if (!isThereParent && !isUnder13) {
        openModal('RequestParentApproval', {
          onClose: () => {
            openModal('StudentPaymentWarning');
          },
        });

        return;
      }

      if (parentApprovalStatus === 'PENDING' && !isParentPhoneVerified) {
        openModal('WarningModal', {
          title: 'Parent Approval Required',
          description:
            'Your parent approval request has already been sent. Please ask them to respond to the SMS text message to approve and upgrade.',
        });
        return;
      }

      if (parentApprovalStatus === 'PENDING' && isParentPhoneVerified) {
        openModal('WarningModal', {
          title: 'Parent Approval Required',
          description:
            'Your parent approval request has already been sent. Please ask them to respond to the SMS text message to approve and upgrade.',
        });
        return;
      }

      if (parentApprovalStatus === 'CONFIRMED' && !isParentPhoneVerified) {
        // resend the sms
        await sendParentApproval({
          parentId: user?.parent?.id,
          confirmationType: 'CHILD_SIGNUP',
        }).unwrap();
        openModal('WarningModal', {
          title: 'Your request has been sent',
          description:
            'Your parent payment upgradation request has already been sent. Please ask them to respond to the SMS text message to approve and upgrade.',
        });
        return;
      }

      if (
        parentApprovalStatus === 'CONFIRMED' &&
        isParentPhoneVerified &&
        !user?.isUserUpgradedPlan
      ) {
        // resend the sms
        await sendParentApproval({
          parentId: user?.parent?.id,
          confirmationType: 'CHILD_UPGRADE_OFFER',
        }).unwrap();

        openModal('WarningModal', {
          title: 'Parent Approval Required',
          description:
            'Your parent approval request has already been sent. Please ask them to respond to the SMS text message to approve and upgrade.',
        });
        return;
      }

      if (user?.isUserUpgradedPlan) {
        openModal('WarningModal', {
          title: 'Congratulations!',
          description:
            'Your parent has already approved and upgraded your account, you may use all premium features for G3MS!',
        });
        return;
      }

      if (parentApprovalStatus === 'REJECTED') {
        // resend the sms
        await sendParentApproval({
          parentId: user?.parent?.id,
          confirmationType: 'CHILD_SIGNUP',
        }).unwrap();

        openModal('WarningModal', {
          title: 'Parent Approval Required',
          description:
            'Your parent approval request has already been sent. Please ask them to respond to the SMS text message to approve and upgrade.',
        });
        return;
      }

      closeModal();
    } catch (error) {
      openModal('WarningModal', {
        title: 'Error',
        description: error.data?.message || 'An error occurred',
      });
    }
  }, [data]);

  return (
    <Modal
      opened={isOpen('StudentPaymentWarning')}
      onClose={closeModal}
      size={'xl'}
      centered
      classNames={{
        body: '!p-0 !shadow-lg inner-shadow',
      }}
      radius={'lg'}
      zIndex={999999999999}
      withCloseButton={false}
    >
      <CloseButton />
      <div className="flex flex-col gap-8 p-5">
        <Lottie animationData={animation} className="mx-auto w-48 lg:w-56" />

        <div className="grid gap-4">
          <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
            🎉 Great Job, {userName}! You’ve Earned $50 G3MS Tokens! 🎉
          </h1>

          <p className="text-sm lg:text-base 2xl:text-xl text-black text-center mx-auto max-w-[45ch]">
            Use your tokens to unlock fun gift cards, cool avatar upgrades, and
            more exciting rewards!
          </p>
        </div>

        <h1 className="text-[#2C3E50] font-bold text-base xl:text-lg  text-center mx-auto">
          💎 “Ready to Glow Up?” 💎
        </h1>

        <ul className="text-sm xl:text-base lg:text-lg  2xl:text-xl text-black">
          <li>🎁 Redeem tokens for gift cards.</li>
          <li>✏️ AI Tutor for math, writing, and coding. </li>
          <li>📚 Unlock full lessons in ELA, Science, and more.</li>
          <li>🚀 Join coding challenges.</li>
          <li>🎓 Prep for high school & SAT/ACT.</li>
        </ul>

        <h1 className="text-[#2C3E50] font-bold text-base xl:text-lg  text-center mx-auto">
          🌟 Ready to Level Up? 🌟
        </h1>
        <h1 className="text-[#2C3E50] font-bold text-base xl:text-lg  text-center mx-auto">
          Try Premium free for 7 days! Then keep unlocking gift cards and
          <br />
          features for just $7.99/month!
        </h1>

        <div className="grid gap-4 grid-cols-1 xl:grid-cols-2">
          <Button
            className="max-w-none w-full"
            onClick={onNext}
            isLoading={isRequestingParent}
          >
            Ask Your Parent to Upgrade!
          </Button>
          <Button
            className="max-w-none w-full"
            variant="primary"
            onClick={() => {
              closeModal();
              navigate('/plans', {
                state: { isStudent: true },
              });
            }}
          >
            Unlock Premium Now!
          </Button>
        </div>
      </div>

      <LoadingOverlay isLoading={isLoading} />
    </Modal>
  );
}
