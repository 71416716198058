import React from 'react';
import { Box, Card, Typography } from '@mui/material';

function EmptyChatTab({heading, detail, image}) {
    return (
        <div className="border px-3 py-2 border-[#E9EAEB] rounded-lg lg:w-1/4">
        <div className="flex justify-start items-center">
          <div >
            <img
              class="h-12 w-12 "
              src={image}
              alt=""
            />
       
          </div>
          <div className="pl-2">
            <Typography sx={{ fontSize: 14, fontWeight: 700 }} color="#181D27">
           {heading}
            </Typography>
           
          </div>
        </div>
        <div className="flex justify-start items-center">
       
          <div className="pl-2">
            <Typography sx={{ fontSize: 12, fontWeight: 400 }} color="#535862">
            {detail}
            </Typography>
           
          </div>
        </div>
      
      </div>
    );
}

export default EmptyChatTab;