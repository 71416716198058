import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useMediaQuery } from '@mui/material';
import Logo from 'components/Logo';
import MobileSlider from './components/MobileSlider';
import OnboardingFooter from './components/OnboardingFooter';
import WebSlider from './components/WebSlider';



export default function OnBoarding() {
  const isMobile = useMediaQuery('(max-width: 1024px)');


  return (
    <section className="bg-[#D9E4E2] h-[100dvh] p-4">
      <div className="bg-white rounded-[2rem] p-4 h-full overflow-x-hidden max-h-[calc(100dvh-2rem)]">
        <div className="flex items-center justify-center py-4">
          <Logo />
        </div>

        {isMobile ? <MobileSlider /> : <WebSlider />}
        <OnboardingFooter />
      </div>
    </section>
  );
}
