// ** React Imports
import Intercom, {
  hide,
  onHide,
  onShow,
  show,
} from '@intercom/messenger-js-sdk';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
// ** MUI Imports
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// ** Icons Imports
import { LogoutOutlined } from '@mui/icons-material';
import { HelpCircleOutline } from 'mdi-material-ui';
import { useDispatch } from 'react-redux';
import { apiSlice } from 'store/apis';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { logoutUser } from 'store/Slice/authSlice';
import { useAppSelector } from 'store/store';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

function capitalizeString(inputString) {
  if (!inputString) {
    return ''; // Return an empty string if the input is empty or falsy
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

function getTypeLabel(type, grade) {
  let label = capitalizeString(type);
  if (grade.length) {
    label += ` - ${grade[0]?.grade?.name}`;
  }
  return label;
}

const UserDropdown = (props) => {
  // ** Props
  const { settings } = props;
  // ** States
  const [anchorEl, setAnchorEl] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { user: userData } = useAppSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userPersonalData, isLoading } = useGetMyProfileQuery();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    if (url) {
      navigate(url);
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('header');
    dispatch(logoutUser());
    navigate('/');
  };

  Intercom({
    app_id: 'gp5at1lo',
    user_id: userData.id,
    name: userData.name,
    email: userData.email,
  });

  useEffect(() => {
    onHide(() => setIsChatOpen(false));
    onShow(() => setIsChatOpen(true));
  }, []);
  useEffect(() => {
    console.log('user', userData);
  }, []);

  return (
    <div className="flex items-center gap-5">
      <button
        className="flex items-center"
        onClick={() => {
          isChatOpen ? hide() : show();
          setIsChatOpen(!isChatOpen);
          // Opens the default email client with a pre-filled email
          // window.location.href =
          //   'mailto:help@getg3ms.com?subject=I%20need%20help%20with%20student%20UI';
        }}
      >
        <HelpCircleOutline className="mr-2 text-[#484848] !size-8" />
        <span className="text-[#131515] font-semibold text-sm md:text-base xl:text-lg">
          Help
        </span>
      </button>
      <button className="flex items-center" onClick={handleLogout}>
        <LogoutOutlined className="mr-2 text-[#484848] !size-8" />
        <span className="text-[#131515] font-semibold text-sm md:text-base xl:text-lg">
          Logout
        </span>
      </button>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        onClick={handleDropdownOpen}
      >
        <Badge
          overlap="circular"
          sx={{ ml: 2, cursor: 'pointer' }}
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Avatar
            alt={userPersonalData?.data.name}
            src={userPersonalData?.data.profileImage}
            sx={{ width: '3rem', height: '3rem' }}
          />
        </Badge>
        <Box
          sx={{
            ml: 2,
            cursor: 'pinter',
            alignItems: 'flex-start',

            //  flexDirection: 'column',
            // width: '40%',
          }}
        >
          <Typography sx={{ fontWeight: 600, color: '#000' }}>
            {userPersonalData?.data.name || userPersonalData?.data.email || ''}
          </Typography>
          <Typography
            variant="body2"
            lineHeight={1}
            sx={{
              fontSize: '0.8rem',
              color: 'text.secondary',
              fontWeight: '500',
            }}
            className="text-[#000000]"
          >
            {/**
             *  {capitalizeString(
              userData?.gradeName + ', ' + userData?.type || 'Student',
            )}
             */}
            {capitalizeString(
              userPersonalData?.data.grade?.name +
                ', ' +
                userPersonalData?.data.type || 'Student',
            )}
          </Typography>
          <Typography
            variant="body2"
            lineHeight={1}
            sx={{ fontSize: 10, color: 'text.secondary', fontWeight: '500' }}
            className="text-[#000000]"
          >
            {userPersonalData?.data.school?.name}
          </Typography>

          {/*  <Typography
            variant="body2"
            sx={{ fontSize: '0.6rem', color: 'text.disabled' }}
          >
            {capitalizeString(
              userData?.school ? userData?.schoolName : 'School',
            )}
          </Typography> */}
        </Box>

        {/*  <IconButton
          sx={{ ml: 2, height: '3rem', width: '3rem' }}
          onClick={(e) => handleDropdownOpen(e)}
        >
          <KeyboardArrowDownIcon />
        </IconButton> */}
      </Box>
    </div>
  );
};
// <Menu
//   anchorEl={anchorEl}
//   open={Boolean(anchorEl)}
//   onClose={() => handleDropdownClose()}
//   sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
//   anchorOrigin={{
//     vertical: 'bottom',
//     horizontal: direction === 'ltr' ? 'right' : 'left',
//   }}
//   transformOrigin={{
//     vertical: 'top',
//     horizontal: direction === 'ltr' ? 'right' : 'left',
//   }}
// >
//   <Box sx={{ pt: 2, pb: 3, px: 4 }}>
//     <Box sx={{ display: 'flex', alignItems: 'center' }}>
//       <Badge
//         overlap="circular"
//         badgeContent={<BadgeContentSpan />}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right',
//         }}
//       >
//         <Avatar
//           alt={userData?.name}
//           src={userData?.profileImage}
//           sx={{ width: '2.5rem', height: '2.5rem' }}
//         />
//       </Badge>
//       <Box
//         sx={{
//           display: 'flex',
//           ml: 3,
//           alignItems: 'flex-start',
//           flexDirection: 'column',
//         }}
//       >
//         <Typography sx={{ fontWeight: 600 }}>
//           {userData?.name || userData?.email || ''}
//         </Typography>
//         <Typography
//           variant="body2"
//           lineHeight={1}
//           sx={{ fontSize: '0.8rem', color: 'text.disabled' }}
//         >
//           {capitalizeString(
//             userData?.gradeName + ', ' + userData?.type || 'Student',
//           )}
//         </Typography>
//         <Typography
//           variant="body2"
//           sx={{ fontSize: '0.6rem', color: 'text.disabled' }}
//         >
//           {capitalizeString(
//             userData?.school ? userData?.schoolName : 'School',
//           )}
//         </Typography>
//       </Box>
//     </Box>
//   </Box>
//   <MenuItem
//     sx={{ py: 2 }}
//     onClick={() => navigate('/app/student/profile')}
//   >
//     <ProfileCircle
//      className='mr-2'
//     />
//     Profile
//   </MenuItem>
//   <MenuItem
//     sx={{ py: 2 }}
// onClick={() => {
//   // Opens the default email client with a pre-filled email
//   window.location.href = "mailto:help@getg3ms.com?subject=I%20need%20help%20with%20student%20UI";
// }}
//   >
//     <HelpCircleOutline sx={{ mr: 2 }} />
//     Help
//   </MenuItem>

//   <Divider sx={{ mt: 0, mb: 1 }} />
//   {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/user/view/12')}>
//     <Box sx={styles}>
//       <AccountOutline sx={{ mr: 2 }} />
//       Profile
//     </Box>
//   </MenuItem>
//   <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/email')}>
//     <Box sx={styles}>
//       <EmailOutline sx={{ mr: 2 }} />
//       Inbox
//     </Box>
//   </MenuItem>
//   <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/chat')}>
//     <Box sx={styles}>
//       <MessageOutline sx={{ mr: 2 }} />
//       Chat
//     </Box>
//   </MenuItem>
//   <Divider />
//   <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/account-settings')}>
//     <Box sx={styles}>
//       <CogOutline sx={{ mr: 2 }} />
//       Settings
//     </Box>
//   </MenuItem>
//   <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/pricing')}>
//     <Box sx={styles}>
//       <CurrencyUsd sx={{ mr: 2 }} />
//       Pricing
//     </Box>
//   </MenuItem>
//   <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/faq')}>
//     <Box sx={styles}>
//       <HelpCircleOutline sx={{ mr: 2 }} />
//       FAQ
//     </Box>
//   </MenuItem>
//   <Divider /> */}
//   <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
//     <LogoutVariant
//       sx={{ mr: 2, fontSize: '1.375rem', color: 'text.secondary' }}
//     />
//     Logout
//   </MenuItem>
// </Menu>

export default UserDropdown;
