import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'store/Slice/authSlice';
import { apiSlice } from 'store/apis';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { useAppSelector } from 'store/store';
import AppStore from '../../../asset/dashboard/app-store.svg';
import Avatar from '../../../asset/dashboard/avatar.svg';
import Dots from '../../../asset/dashboard/dots.png';
import PlayStore from '../../../asset/dashboard/google.svg';
import Menu from '../../../asset/dashboard/menu-01.svg';
import Logo from '../../../asset/logo.svg';
//import ProfileInfo from 'app/student/components/studentProfile/components/ProfileInfo';

const Navbar = ({ menuToggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { user: userData } = useAppSelector((state) => state.authReducer);
  const { data: data } = useGetMyProfileQuery();

  useEffect(() => {
    console.log('***********userDataQuery', data);
    // handleLogout()
    // const handleClickOutside = (event) => {
    // if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //     setIsOpen(false);
    //     return
    // }
    // };
    // document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    // document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, []);
  const handleClick = (item) => {
    if (item == 'Profile') {
      navigate('/app/student/profile');
    } else if (item == 'My Accounts') {
      navigate('/plans');
    } else if (item == 'My Assignments') {
      navigate('/app/student/assignment');
    } else if (item == 'Discover') {
      navigate('/app/student/discover');
    }
  };
  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('header');
    dispatch(logoutUser());
    navigate('/');
  };
  return (
    <>
      <div className=" w-full justify-between xl:flex bg-white p-4 rounded-3xl">
        <div className="w-full flex justify-between items-center">
          <div className=" hidden lg:flex">
            <div className="flex items-center gap-4">
              <h3 className="text-lg text-wrap font-bold   text-[#2C3E50]">
                Learn what matters, earn what counts with G3MS
              </h3>

              <div className="flex items-center gap-3">
                <div
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.g3ms',
                      '_blank',
                    )
                  }
                  className="cursor-pointer"
                >
                  {' '}
                  <img className="h-10 " src={PlayStore} alt="" />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/us/app/g3ms/id1625313492',
                      '_blank',
                    )
                  }
                  className="cursor-pointer"
                >
                  {' '}
                  <img className="h-10 " src={AppStore} alt="" />
                </div>

                <a
                  className="cursor-pointer bg-[#C44297] text-white rounded-lg px-1 py-4 font-bold text-xs xl:text-sm flex items-center gap-2 h-8 lg:h-10 2xl:h-12"
                  href="https://codepen.io/pen/"
                  target="_blank"
                  rel="noreferrer"
                >
                  CodePen Editor
                </a>
              </div>
            </div>
          </div>
          <div>
            <img
              onClick={menuToggle}
              className=" cursor-pointer lg:hidden block h-9 mb-2 "
              src={Logo}
              alt="logo"
            />
          </div>

          <div className="text-3xl font-normal">
            <div className="lg:hidden">Dashbaord</div>
          </div>

          <div ref={dropdownRef} className="flex items-center ">
            <div className="hidden md:hidden lg:flex items-center space-x-4">
              <img
                onClick={() => setIsOpen((prev) => !prev)}
                className="w-16 h-16 border border-gray-300 rounded-full object-fit cursor-pointer"
                src={data?.data.profileImage || Avatar}
                alt=""
              />
              <div
                onClick={() => setIsOpen((prev) => !prev)}
                className="flex flex-col cursor-pointer"
              >
                <h4 className="text-xl font-semibold text-[#131515]">
                  {data?.data.name || data?.data.email || ''}
                </h4>
                <span className="text-md font-medium">
                  @{data?.data.username || ''}, {data?.data.gradeName || 'N/A'}
                </span>
                <span className="text-md font-medium">
                  {data?.data.schoolName || ''}
                </span>
                <img
                  className="max-w-6 cursor-pointer mx-auto"
                  src={Dots}
                  alt=""
                />
              </div>
            </div>
            <div className="block md:block lg:hidden">
              <img
                onClick={() => setIsOpen((prev) => !prev)}
                src={Menu}
                alt="hamburger"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`absolute top-[86%] w-[32%] right-9 mt-2 bg-white border border-[#00000033] rounded-3xl shadow-lg p-3 transition-all duration-300 transform ${
          isOpen
            ? 'opacity-100 scale-100'
            : 'opacity-0 scale-95 pointer-events-none'
        }`}
      >
        {/** profile <div className='my-2'>
                 <ProfileInfo isborderImage={false} isIcon={true}></ProfileInfo>
                 </div> */}

        {/**
         * real values
         * ['Profile','My Accounts', 'Progress Report', 'My Assignments','Discover', 'G3MS Rewards'] */}
        <ul>
          {['My Accounts']?.map((item, _index) => (
            <div
              onClick={() => handleClick(item)}
              className="flex border items-center justify-between border-[#00000033] rounded-xl cursor-pointer py-2.5 px-4 mb-1.5"
            >
              <li key={_index} className=" text-[#34495E] text-sm font-medium ">
                {item}
              </li>
              <img
                alt=""
                src={require('../../../asset/Vector.png')}
                className="w-1 md:w-1 lg:w-[5px] xl:w-[5px] h-auto object-contain ml-auto"
              />
            </div>
          ))}
          <li
            onClick={handleLogout}
            className="bg-[#C44297] text-white text-sm font-medium rounded-xl cursor-pointer py-2.5 px-4"
          >
            Logout
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
