import { NumberInput, PasswordInput, TextInput } from '@mantine/core';
import { nanoid } from '@reduxjs/toolkit';
import { Eye, EyeSlash } from 'iconsax-react';
import React, { useId } from 'react';
import cn from 'utils/cn';

export default function Input({
  label,
  type = 'text',
  placeholder,
  value,
  onChange,
  required = false,
  disabled = false,
  className = '',
  error,
  ...props
}) {
  const id = useId();

  const VisibilityToggleIcon = ({ reveal }) =>
    reveal ? (
      <EyeSlash className="text-[#484848] size-5" />
    ) : (
      <Eye className="text-[#484848] size-5" />
    );

  if (type === 'number') {
    return (
      <NumberInput
        id={id}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        error={error}
        classNames={{
          input: cn(
            'block w-full !px-3 !py-3 text-base xl:text-lg !border focus:!border  !border-[#DCDCDC] !rounded-lg !shadow-sm lg:!text-base  justify-center',
            'focus:!border-[#C73E9D] focus:!ring-1 focus:!ring-[#C73E9D] transition-all duration-200 ease-in-out',
            { 'border-[#F41F52] focus:border-[#F41F52]': !!error },
            className,
          ),
          label: 'block !font-medium !text-[#484848] !text-base xl:!text-base',
          error: '!text-[#F41F52] !text-sm xl:!text-sm',
          root: 'space-y-[0.5rem]',
        }}
        size="lg"
        visibilityToggleIcon={(props) => <VisibilityToggleIcon {...props} />}
        name={nanoid()}
        hideControls
        {...props}
      />
    );
  }

  if (type === 'password') {
    return (
      <PasswordInput
        id={id}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        error={error}
        classNames={{
          input: cn(
            'block w-full !px-3 !py-3 text-base xl:text-lg !border focus:!border  !border-[#DCDCDC] !rounded-lg !shadow-sm lg:!text-base  justify-center',
            'focus:!border-[#C73E9D] focus:!ring-1 focus:!ring-[#C73E9D] transition-all duration-200 ease-in-out',
            { 'border-[#F41F52] focus:border-[#F41F52]': !!error },
            className,
          ),
          label: 'block !font-medium !text-[#484848] !text-base xl:!text-base',
          error: '!text-[#F41F52] !text-sm xl:!text-sm',
          root: 'space-y-[0.5rem]',
        }}
        size="lg"
        visibilityToggleIcon={(props) => <VisibilityToggleIcon {...props} />}
        name={nanoid()}
        {...props}
      />
    );
  }

  return (
    <TextInput
      id={id}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      error={error}
      classNames={{
        input: cn(
          'block w-full !px-3 !py-3 text-base xl:text-lg !border focus:!border  !border-[#DCDCDC] !rounded-lg !shadow-sm lg:!text-base  justify-center',
          'focus:!border-[#C73E9D] focus:!ring-1 focus:!ring-[#C73E9D] transition-all duration-200 ease-in-out',
          { 'border-[#F41F52] focus:border-[#F41F52]': !!error },
          className,
        ),
        label: 'block !font-medium !text-[#484848] !text-base xl:!text-base',
        error: '!text-[#F41F52] !text-sm xl:!text-sm',
        root: 'space-y-[0.5rem]',
      }}
      size="lg"
      name={nanoid()}
      {...props}
    />
  );
}

// return (
//   <fieldset className="space-y-2 appearance-none">
//     {label && (
//       <label
//         htmlFor={id}
//         className="block font-medium text-[#484848] text-base xl:text-lg"
//       >
//         {label}
//       </label>
//     )}
//     <div className="relative">
//       <input
//         id={id}
//         type={showPassword ? 'text' : type}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         required={required}
//         disabled={disabled}
//         className={cn(
//           'block w-full px-3 py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center',
//           'focus:!border-[#C73E9D] focus:!ring-1 focus:!ring-[#C73E9D] transition-all duration-200 ease-in-out',
//           { 'border-[#F41F52] focus:border-[#F41F52]': !!error },
//           className,
//         )}
//         {...props}
//       />
//       {type === 'password' && (
//         <button
//           type="button"
//           className="absolute inset-y-0 right-0 flex items-center px-3 text-[#484848] focus:outline-none"
//           onClick={() => setShowPassword((prev) => !prev)}
//         >
//           {showPassword ? (
//             <EyeSlash className="text-[#484848] size-5" />
//           ) : (
//             <Eye className="text-[#484848] size-5" />
//           )}
//         </button>
//       )}
//     </div>

//     {error && <p className="text-[#F41F52] text-sm xl:text-base">{error}</p>}
//   </fieldset>
// );
