import { createSlice } from '@reduxjs/toolkit';

const intialState = {
  showCodeToEarn: false,
  tempUser: null,
  isLoading: false,
};

const uiSlice = createSlice({
  name: 'ui_slice',
  initialState: intialState,
  reducers: {
    setShowCodeToEarn: (state, action) => {
      state.showCodeToEarn = action.payload;
    },
    setTempUser: (state, action) => {
      state.tempUser = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setShowCodeToEarn, setTempUser, setIsLoading } = uiSlice.actions;

export const selectShowCodeToEarn = (state) => state.uiReducer.showCodeToEarn;
export const selectTempUser = (state) => state.uiReducer.tempUser;
export const selectIsLoading = (state) => state.uiReducer.isLoading;

const uiReducer = uiSlice.reducer;

export default uiReducer;
