import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

import {
  useApproveOrRejectChildMutation,
  useGetDetailsByPaymentConfirmationIdQuery,
} from 'store/apis/payment';
import LoadingOver from 'app/components/LoadingOverlay';
import { Modal } from '@mantine/core';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { logoutUser } from 'store/Slice/authSlice';
import { auth } from 'api/firebase';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import useModal from 'components/Modal/hook/useModal';
import { useDispatch } from 'react-redux';

const ChildUpgradeApproval = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { checkProfileAndNavigate } = useFirebaseLogin();
  const { openModal } = useModal();

  const [searchParams] = useSearchParams();

  const confirmationId = searchParams.get('confirmationId');
  const parentId = searchParams.get('parentId');

  const [approveOrReject, approveOrRejectResponse] =
    useApproveOrRejectChildMutation();

  const approveOrRejectChild = async (confirmationStatus = 'CONFIRMED') => {
    try {
      dispatch(logoutUser());
      await auth.signOut();
      const res = await approveOrReject({ confirmationId, confirmationStatus });
      if (confirmationStatus === 'CONFIRMED') {
        const phoneToken = res.data?.data?.verificationSid;
        const phone = res.data?.data?.parentConfirmation?.parent?.phone;
        const localParentId = res.data?.data?.parentConfirmation?.parent?.id;
        localStorage.setItem('childApproval', 'approved');
        console.log(res.data);

        if (!res.data.data.isParentPhoneVerified) {
          navigate({
            pathname: '/otp-validation',
            search: `?confirmationId=${confirmationId}&parentId=${
              parentId || localParentId
            }&phoneToken=${phoneToken}&redirectTo=plans&phone=${phone}`,
          });
          return;
        }

        if (res.data.data.isParentUserUpgradedToAPlan) {
          const parentUser = res.data.data.parentUser;
          const customToken = parentUser?.customToken;
          const user = parentUser?.user;

          if (customToken && user?.type === 'student') {
            await auth.signInWithCustomToken(customToken);
            const token = await auth.currentUser.getIdToken();
            localStorage.setItem('token', token);

            checkProfileAndNavigate(user, token, () => {
              navigate('/complete-profile');
            });
            return;
          }

          openModal('WarningModal', {
            title: 'Request Confirmed',
            description:
              "The request has been confirmed, Let's complete your profile.",
          });
          /* 
           if (!user?.dateOfBirth && user?.phone) {
             navigate('/birth?phoneNumber=' + user?.phone);
             return;
           }
 
           if (!user?.dateOfBirth && !user?.phone) {
             navigate('/birth');
             return;
           }
 
           if (user?.dateOfBirth && !user?.phone) {
             navigate(
               '/phone-validation?birthDate=' +
                 dayjs(user?.dateOfBirth).format('YYYY-MM-DD'),
             );
             return;
           } */
          if (!user?.isPhoneVerified && user?.type !== 'student') {
            const query = queryString.stringify({
              phone: user?.phone,
              birthDate: user?.dateOfBirth
                ? dayjs(user?.dateOfBirth).format('YYYY-MM-DD')
                : undefined,
            });
            navigate('/phone-validation?' + query);
            return;
          }

          if (!customToken) {
            const query = queryString.stringify({
              phone: user?.phone,
              birthDate: user?.dateOfBirth
                ? dayjs(user?.dateOfBirth).format('YYYY-MM-DD')
                : undefined,
            });
            navigate('/over13?' + query);
            return;
          }

          if (customToken) {
            await auth.signInWithCustomToken(customToken);
            const token = await auth.currentUser.getIdToken();
            localStorage.setItem('token', token);

            checkProfileAndNavigate(user, token, () => {
              navigate('/complete-profile');
            });
            return;
          }

          return;
        }

        navigate({
          pathname: '/plans',
          search: `?confirmationId=${confirmationId}&parentId=${
            parentId || localParentId
          }`,
        });

        return;
      }

      if (confirmationStatus === 'REJECTED') {
        openModal('WarningModal', {
          title: 'Request Rejected',
          description: 'The request has been rejected.',
        });
        localStorage.clear();
        navigate('/');
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } =
    useGetDetailsByPaymentConfirmationIdQuery(confirmationId);

  return (
    <Modal
      opened={true}
      onClose={() => {}}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      size={'50rem'}
      centered
      classNames={{
        body: '!p-0 !shadow-lg inner-shadow',
      }}
      radius={'lg'}
    >
      <div className="px-4 py-8">
        <header className={styles.convinceToUpgradeHead}>
          <h3 className={styles.heading}>Your Child Requested an Upgrade</h3>
        </header>
        <main className={styles.convinceToUpgradeBody}>
          <h3 className={styles.heading}>
            {data?.data?.child?.name} {data?.data?.child?.lastname} is asking
            you to upgrade their G3MS account to unlock premium features.
          </h3>
          <h3 className={styles.heading} style={{ textAlign: 'left' }}>
            Premium Features Include
          </h3>
          <p className={styles.bodyTxt} style={{ textAlign: 'left' }}>
            Here’s what {data?.data?.child?.name} {data?.data?.child?.lastname}{' '}
            will enjoy with G3MS Premium:
          </p>
          <ul>
            <li>
              ✅ Full access to Math, ELA, Science, and Social Studies lessons.
            </li>
            <li>✅ Personalized AI Tutor for tailored learning support.</li>
            <li>✅ Earn redeemable tokens for rewards and gift cards.</li>
            <li>✅ Advanced progress tracking and analytics.</li>
          </ul>
          <div>
            <h3 className={styles.heading}>
              Plans start at $7.99/month with a 7-day free trial.
            </h3>
            <h3 className={styles.heading} style={{ marginBottom: '0px' }}>
              Cancel anytime with no commitment.
            </h3>
          </div>
        </main>
        <footer className={styles.convinceToUpgradeFooter}>
          <button className="order-2 lg:order-1" onClick={() => navigate('/')}>
            Reject
          </button>
          <button
            onClick={() => {
              approveOrRejectChild('CONFIRMED');
            }}
            className="order-1 lg:order-2"
          >
            Approve & View Payment Options
          </button>
        </footer>

        <LoadingOver isLoading={isLoading} />
      </div>
    </Modal>
  );
};

export default ChildUpgradeApproval;
