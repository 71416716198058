import React, { useState } from 'react';

import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import { useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { NotificationManager } from 'react-notifications';
import OTPInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import {
  useSendEmailOtpMutation,
  useSendPhoneOtpMutation,
  useVerifyPhoneOtpMutation,
} from 'store/apis/otp';
import * as Yup from 'yup';
import Timer from './components/Timer';

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .length(6, 'OTP must be 6 characters')
    .required('OTP is required'),
});

export default function OtpValidationScreen({}) {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const phoneNumber = searchParams.get('phone');
  const birthDate = searchParams.get('birthDate');

  const type = searchParams.get('type') || 'phone';
  const email = searchParams.get('email');
  const redirectTo = searchParams.get('redirectTo');

  const confirmationId = searchParams.get('confirmationId');
  const parentId = searchParams.get('parentId');

  const [isTimeExpired, setIsTimeExpired] = useState(false);

  const { openModal, closeModal } = useModal();

  const [phoneToken, setPhoneToken] = useState(searchParams.get('phoneToken'));

  const containerRef = React.useRef(null);

  const [verifyPhoneOtp, verifyPhoneOtpRes] = useVerifyPhoneOtpMutation();

  const [sendPhoneOtp, phoneOtpRes] = useSendPhoneOtpMutation();
  const [sendEmailOtp, emailOtpRes] = useSendEmailOtpMutation();

  const isLoading = verifyPhoneOtpRes.isLoading || phoneOtpRes.isLoading;

  const handleResend = async () => {
    try {
      if (type === 'email' && email) {
        // TODO: Implement sendEmailOtp
        // const res = await sendEmailOtp({
        //   email,
        // }).unwrap();
        // setPhoneToken(res.data.verificationSid);
        await sendEmailOtp({
          email,
        }).unwrap();
        setIsTimeExpired(true);
        return;
      }
      const parsedPhoneNumber = parsePhoneNumber(
        phoneNumber?.includes('+') ? phoneNumber : `+${phoneNumber}`,
      );
      const res = await sendPhoneOtp({
        countryCode: `+${parsedPhoneNumber.countryCallingCode}`,
        phoneNumber: parsedPhoneNumber.nationalNumber,
      }).unwrap();
      setPhoneToken(res.data.verificationSid);
      setIsTimeExpired(true);
      closeModal();
    } catch (error) {
      const err = error;
      NotificationManager.error(err.data.message || 'Something went wrong');
    } finally {
      // setIsTimeExpired(true);
      closeModal();
    }
  };

  const handleVerify = async (otp) => {
    try {
      if (type === 'email' && email) {
        await verifyPhoneOtp({
          emailCode: otp,
        }).unwrap();
        openModal('OtpSuccessModal', {
          onContinue: () => {
            closeModal();

            navigate(
              `${redirectTo}?parentId=${parentId}&confirmationId=${confirmationId}` ||
                '/app/student',
            );
          },
        });
        return;
      }

      const res = await verifyPhoneOtp({
        otp,
        verificationSid: phoneToken,
      }).unwrap();
      if (res.data.isOtpValid) {
        if (redirectTo === 'plans') {
          navigate({
            pathname: '/plans',
            search: `?confirmationId=${confirmationId}&parentId=${parentId}&phoneToken=${phoneToken}`,
          });
          return;
        }
        openModal('OtpSuccessModal', {
          onContinue: () => {
            closeModal();
            const url = `/over13?phone=${phoneNumber}&phoneToken=${phoneToken}&birthDate=${birthDate}`;
            navigate(url, {
              replace: true,
            });
          },
        });
      } else {
        openModal('InvalidOtpModal', {
          onResend: handleResend,
        });
      }
    } catch (error) {
      openModal('InvalidOtpModal', {
        onResend: handleResend,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    onSubmit: (values) => {
      handleVerify(values.otp);
    },
    validationSchema: validationSchema,
  });

  return (
    <AuthPage className="gap-4 content-center">
      <Logo className="w-32 mb-4" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>Verify Your Phone Number</AuthDetails.Title>
        <AuthDetails.Description>
          To ensure the security of your account, we’ve sent a 6-digit
          verification code to{' '}
          {type === 'email' ? email : `+${phoneNumber?.trim()}`}
        </AuthDetails.Description>
      </AuthDetails>
      <form className=" grid gap-y-3 w-full" onSubmit={formik.handleSubmit}>
        <label className="w-full block font-medium text-lg text-left color-[#484848]">
          Verification Code
        </label>
        <div className="grid max-w-[30rem]" ref={containerRef}>
          <OTPInput
            value={formik.values.otp}
            onChange={(otp) => formik.setFieldValue('otp', otp)}
            numInputs={6}
            renderInput={(props) => (
              <input
                {...props}
                className="border border-[#DCDCDC] rounded-md !h-14 !w-12 sm:!w-14 md:!w-16 xl:!w-20 text-center"
                // style={{ width: '2rem', height: '2rem' }}
                placeholder="0"
              />
            )}
            containerStyle={'!flex !justify-between gap-2 w-full'}
            inputStyle={
              'border border-[#DCDCDC] rounded-md w-20 h-14 text-center'
            }
          />
        </div>
        <div className="flex justify-between max-xl:flex-col xl:items-center gap-4">
          {!isTimeExpired ? (
            <div className="flex items-center gap-2">
              <p className="text-sm xl:text-base font-medium text-[#666666]">
                Didn’t receive the code?
              </p>
              <button
                onClick={handleResend}
                className="text-sm xl:text-base font-medium text-[#C44297]"
              >
                Resend
              </button>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <p className="text-sm xl:text-base font-medium text-[#666666]">
                Resend OTP in
              </p>
              <Timer time={120} onTimeEnd={() => setIsTimeExpired(false)} />
            </div>
          )}

          {type !== 'email' && (
            <button
              onClick={() =>
                navigate(-1, {
                  replace: true,
                })
              }
              my={3}
            >
              <p className="text-sm xl:text-base font-medium text-[#C44297]">
                Change phone number
              </p>
            </button>
          )}
        </div>
        <Button
          variant={'primary'}
          type="submit"
          className={'max-w-[25rem] mt-8 mx-auto'}
          isLoading={
            isLoading || verifyPhoneOtpRes.isLoading || emailOtpRes.isLoading
          }
        >
          Next
        </Button>
      </form>
    </AuthPage>
  );
}
