// ** React Imports
import { useState } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp';

// ** Theme Config Import
import themeConfig from 'configs/themeConfig';

// ** Components
import ScrollToTop from '@core/components/scroll-to-top';
import { useTheme } from '@emotion/react';

import VerifyEmail from 'app/student/components/VerifyEmail';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';
import AppBar from './components/vertical/appBar';
import Navigation from './components/vertical/navigation';

// ** Styled Component
// import DatePickerWrapper from '@core/styles/libs/react-datepicker'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
});

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  height: '100dvh',
  flexDirection: 'column',
  overflowY: 'scroll',
  padding: '1rem',
});

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  transition: 'padding .25s ease-in-out',
  height: 'calc(100dvh - 1rem - 150px)',
  overflow: 'hidden',
}));

const VerticalLayout = (props) => {
  // ** Props
  const { hidden, settings, children, scrollToTop } = props;
  const theme = useTheme();

  // ** Vars
  const { skin, navHidden, contentWidth } = settings;
  const { navigationSize, disableCustomizer, collapsedNavigationSize } =
    themeConfig;
  const navWidth = navigationSize;
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0;
  const collapsedNavWidth = collapsedNavigationSize;

  // ** States
  const [navHover, setNavHover] = useState(false);
  const [navVisible, setNavVisible] = useState(false);

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);
  const { data, isLoading } = useGetMyProfileQuery();
  // const isEmailVerified = data?.data?.isEmailVerified;
  const isEmailVerified = true;

  return (
    <>
      <VerifyEmail />
      <VerticalLayoutWrapper className="layout-wrapper">
        {navHidden &&
        themeConfig.layout === 'vertical' &&
        !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <Navigation
            navWidth={navWidth}
            navHover={navHover}
            navVisible={navVisible}
            setNavHover={setNavHover}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            {...props}
          />
        )}
        <MainContentWrapper
          className={cn('layout-content-wrapper', {
            '!mt-[70px] h-[calc(100dvh-70px)] xl:!mt-[80px] xl:h-[calc(100dvh-80px)] z-50':
              !isEmailVerified && !isLoading,
          })}
        >
          <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />

          <ContentWrapper
            className="layout-page-content !overflow-y-scroll scrollbar-hidden"
            sx={{
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
              }),
            }}
          >
            {children}
          </ContentWrapper>

          {/* <DatePickerWrapper sx={{ zIndex: 11 }}>
            <Box id='react-datepicker-portal'></Box>
          </DatePickerWrapper> */}
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </>
  );
};

export default VerticalLayout;
