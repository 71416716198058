import React from 'react';

import Button from 'components/Button';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useGetMyProfileQuery } from 'store/apis/profile';
import getUserName from 'utils/getUserName';
import animation from 'asset/lottie/bot-animation.json';
import Modal from 'components/Modal/components/Modal';
import useModal from 'components/Modal/hook/useModal';
import { auth } from 'api/firebase';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import useHandleUserNavigation from 'hooks/useHandleUserNavigation';

export default function ParentPaymentWarning() {
  const { closeModal, isOpen } = useModal();
  const { data, isLoading } = useGetMyProfileQuery();
  const { loginUser } = useHandleUserNavigation();

  const navigate = useNavigate();

  const userName = React.useMemo(() => {
    if (data?.data) {
      return getUserName({
        firstName: data?.data?.name,
        lastName: data?.data?.lastname,
        username: data?.data?.username,
        type: data?.data?.type ?? 'student',
      });
    }
    return '';
  }, [data]);

  return (
    <Modal isOpen={isOpen('ParentPaymentWarning')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_5%]">
        {/* <Modal.CloseButton /> */}
        <div className="flex flex-col gap-4 py-4">
          <Lottie animationData={animation} className="mx-auto w-48 lg:w-56" />

          <div className="grid gap-4">
            <h1 className="text-[#2C3E50] font-bold text-lg md:text-xl xl:text-2xl text-center mx-auto">
              ✨ Want to Unlock Premium for {userName}?✨
            </h1>
            <h1 className="text-[#2C3E50] font-bold text-base md:text-lg xl:text-xl">
              Upgrade now for just $7.99/month to unlock:
            </h1>
          </div>

          <ul className="text-sm md:text-base lg:text-lg  2xl:text-xl text-black">
            <li>🎁 Redeem tokens for gift cards.</li>
            <li>✏️ AI Tutor for math, writing, and coding.</li>
            <li>📚 Full state-aligned curriculum.</li>
            <li>🚀 Workforce skills and test prep.</li>
          </ul>

          <h1 className="text-[#2C3E50] font-bold text-lg md:text-xl xl:text-2xl text-center mx-auto">
            Start Your Free 7-Day Trial Today!
          </h1>

          <Button
            className="max-w-none w-full"
            variant="primary"
            onClick={async () => {
              closeModal();
              const accessToken = await auth.currentUser?.getIdToken();
              loginUser(data?.data, accessToken, {
                redirectTo: '/plans',
              });
            }}
            isLoading={isLoading}
          >
            Try G3MS for Free Today
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
