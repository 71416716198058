import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { correct } from 'asset';
import AnimatedLogo from 'asset/Bot-lottie1-CL-V1.json'
import Lottie from 'lottie-react';
import Cross from 'asset/Cross.png';

export default function SuccessAnswer(props) {
  const { onClose, isReview } = props;

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={true} onClose={onClose}>
      <DialogContent>
        <Box textAlign={'center'}>
          <div role='button' onClick={onClose} className='flex items-center justify-end'>
          <img
          alt='close'
          src={Cross}
          className='h-4 w-4'
          />
          </div>
        <div className=''>
                <Lottie className=" h-40 "   animationData={AnimatedLogo}></Lottie>
                </div>
          <Box>
            <div className='flex items-center justify-center'>
            <Typography  sx={{ color: '#000', fontWeight: '700', fontSize:"20px" }}>
           You have earned {' '}
            </Typography>
            <div className="flex py-1 px-2 bg-[#e4f1fd] rounded-full ml-2">
                   <img src={require('asset/diamond.png')} alt="" className="h-6 w-5" />
                   <Typography sx={{ fontSize: '14px', fontWeight: 600, color:"#3AAAFF", marginLeft:1 }}>{isReview?" $1": " $5"}</Typography>
                 </div>
            </div>
            <Typography sx={{ color: '#000', fontWeight: '700', fontSize:"15px" }}>
            🎉 You answer is correct!
            </Typography>
          </Box>
          <Button
            sx={{
              textTransform: 'none',
              width: '60%',
              background: '#C73E9D',
              color: '#FFFFFF',
              my: 5,
              '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
            }}
            onClick={onClose}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
