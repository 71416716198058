import { Avatar, Box, Card, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React, { Fragment, forwardRef, useEffect, useState } from 'react';
import VideoActions from './VideoActions';
import crossAvatar from '../../../../../media/add-circle.png';
import userAvatar from '../../../../../media/img-1.png';
import TickAvatar from '../../../../../media/tick-square.png';
import notCompleted from '../../../../../media/EmojiV1.png';
import completed from '../../../../../media/EmojiV2.png';
import notMore from '../../../../../media/EmojiV3.png';
import { secondsToMinutes } from 'utils/index';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import TemplateQuizDialog from 'dialog/TemplateQuiz';
import { getQuizListApi } from 'requests/Template';
import { useGetMyProfileQuery } from 'store/apis/profile';

function LessonComment(
  {
    video,
    fetchData,
    totalVideoDuration,
    videoActivity,
    moreExample,
    moveToNextSkill,
    noMore,
    paused,
    alreadyCompleted,
  },
  ref,
) {
  const navigate = useNavigate();
 // const userData = useAppSelector((state) => state.userReducer.userData);
  const dispatch = useAppDispatch();
  const [failedQuiz, setFailedQuiz] = useState(false);
  const [userData, setUserData] = useState(false);
  const { data: userPersonalData, isLoading } = useGetMyProfileQuery();
  useEffect(()=>{
    setUserData(userPersonalData?.data);
    console.log("video", video)
  },[userPersonalData])
  function removeSeqN(text) {
    const arrs = text.split('_');
    return arrs[arrs.length - 1];
  }
  return (
    <div className="bg-[#F4F4F4] lg:mt-0 mt-4 lg:w-[30%] lg:h-[72vh] overflow-y-scroll p-4 rounded-3xl">
      {video && (
        <>
          <div className="bg-[white]  rounded-2xl p-4">
            <img
              alt={video?.user?.name || 'A'}
              className="w-[60Px] h-[60px]"
              src={userAvatar}
            />
            <Typography
              variant="h6"
              style={{
                fontSize: '20px',
                fontWeight: '600',
                color: '#16191C',
              }}
            >
              {video?.user?.name}
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#808080',
              }}
            >
              {video?.user?.type}
            </Typography>
            <Typography
              alignItems="center"
              display={'flex'}
              color="text.secondary"
              sx={{ fontSize: '18px', color: '#16191C' }}
            >
              {video?.topic?.[0]?.topic?.name} : {removeSeqN(video?.title)}
            </Typography>
          </div>
        </>
      )}

      {parseInt(videoActivity?.timeWatched) > 0 && (
        <Fragment>
          {videoActivity && totalVideoDuration && (
            <>
              <div className="bg-[white] mt-3 rounded-2xl p-2">
                <div className="flex">
                  <Typography paddingRight={'10px'}>
                    <Avatar
                      alt={video?.user?.name || 'A'}
                      sx={{ width: 40, height: 40, borderRadius: 100 }}
                      className="img-cross"
                      src={
                        parseInt(videoActivity?.timeWatched) ===
                        parseInt(totalVideoDuration)
                          ? TickAvatar
                          : crossAvatar
                      }
                    />
                  </Typography>
                  <div>
                    <Typography alignItems="center" display={'flex'}>
                      <div className="font-bold text-[14px] text-[#000000]">
                        Total Time Watch :
                      </div>
                      <Typography
                        fontWeight="700"
                        color={
                          parseInt(videoActivity?.timeWatched) ===
                          parseInt(totalVideoDuration)
                            ? '#C73E9D'
                            : '#3EC7AC'
                        }
                        paddingLeft={'4px'}
                      >
                        {videoActivity
                          ? secondsToMinutes(videoActivity?.timeWatched)
                          : '0 sec'}
                      </Typography>
                    </Typography>
                    <Typography
                      marginTop={3}
                      alignItems="center"
                      display={'flex'}
                      color="text.secondary"
                    >
                      <strong fontWeight="700">Total Length: </strong>
                      {totalVideoDuration
                        ? secondsToMinutes(totalVideoDuration)
                        : '0 min'}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )}
          {videoActivity &&
            totalVideoDuration &&
            !videoActivity?.completed &&
            parseInt(videoActivity?.timeWatched) !==
              parseInt(totalVideoDuration) &&
            !noMore &&
            paused && (
              <div className="bg-[white] mt-3 rounded-2xl p-4">
                <div className="flex mt-4 items-center justify-center">
                  <img
                    alt="sad-emoji"
                    src={notCompleted}
                    className="h-[50px] w-[50px]"
                  />
                </div>
                <Typography
                  fontSize={'21px'}
                  lineHeight={'1.4'}
                  fontWeight={'500'}
                  color={'#000'}
                  marginBottom={'1rem'}
                  textAlign={'center'}
                  marginTop={5}
                >
                  Looks like you did
                  <span className="font-extrabold"> NOT </span>
                  finish watching the video lesson?
                </Typography>
                <div
                  className="flex items-center justify-center mt-5 mb-4"
                  role="button"
                  onClick={() => ref.current.play()}
                >
                  <button className="btn-primary">Continue Lesson</button>
                </div>
              </div>
            )}
          {totalVideoDuration &&
            videoActivity &&
            videoActivity?.completed &&
            parseInt(videoActivity?.timeWatched) ===
              parseInt(totalVideoDuration) &&
            !noMore && (
               <div className="bg-[white] mt-3 rounded-3xl p-4">
               <div className='flex mt-9 items-center justify-center'>
                  <img
                    alt="sad-emoji"
                    src={completed}
                    className="h-[50px] w-[50px]"
                  />
                  </div>
               <Typography
                  fontSize={'16px'}
                  lineHeight={'1.2'}
                  fontWeight={'600'}
                  color={'#000'}
                  marginBottom={'1rem'}
                  textAlign={"center"}
                >
                  {alreadyCompleted ? (
                    <>You have already watched this video and earned $G3MS.</>
                  ) : (
                    <>
                     You have earned
                      <div className='text-[#C73E9D] text-[24px] font-extrabold' style={{ color: '#C73E9D' }}>
                      {videoActivity.tokensEarned} $G3MS!
                      </div>
                    </>
                  )}
                </Typography>
                <Typography
                  fontSize={'18px'}
                  lineHeight={'1.2'}
                  fontWeight={'500'}
                  color={'#000'}
                  marginBottom={'20px'}
                  textAlign={"center"}
                >
                  Great job completing this video lesson. Ready for a quick quiz on this skill?
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={'space-between'}
                  gap={'10px'}
                >
                  {/* <button
                    style={{ width: '50%' }}
                    className="btn-outline"
                    onClick={() => {
                      moreExample();
                    }}
                  >
                    I need more examples
                  </button> */}
                  <button
                    onClick={() => {
                      // updateAssignmentDone({
                      //   assignmentId: video?.Assignments?.[0]?.assignmentId,
                      // }).catch(console.error);
                      getQuizListApi({
                        skillId: video?.skill?.[0]?.skillId,
                        userId: userData?.id,
                        classroomId:
                          video?.Assignments?.[0]?.assignment?.classroomId,
                      })
                        .then((res) => {
                          if (!res.data) {
                            setFailedQuiz(true);
                          } else {
                            moveToNextSkill(true);
                            navigate(
                              `/app/student/daily-quiz?skill=${video?.skill?.[0]?.skillId}`,
                            );
                          }
                        })
                        .catch(console.error);
                    }}
                    className="btn-secondary"
                    style={{ width: '100%' }}
                  >
                    Let's Do it
                  </button>
                </Stack>
               </div>
             
            )}
          <TemplateQuizDialog
            open={failedQuiz}
            onClose={() => {
              moveToNextSkill(true);
              setFailedQuiz(false);
            }}
          />
          {noMore && (
            <div className="bg-[white] mt-4 rounded-3xl p-4">
              <div className="flex mt-2 items-center justify-center">
                <img
                  alt="sad-emoji"
                  src={notMore}
                  className="h-[50px] w-[50px]"
                />
              </div>
              <Typography
                fontSize={'16px'}
                lineHeight={'1.2'}
                fontWeight={'600'}
                color={'#000'}
                marginBottom={'1rem'}
                textAlign={'center'}
                marginTop={5}
              >
                Oops! Looks like we have no more videos to teach you this skill.
              </Typography>
              <Typography
                fontSize={'15px'}
                lineHeight={'1.2'}
                fontWeight={'300'}
                color={'#000'}
                marginBottom={'20px'}
                textAlign={'center'}
              >
                Time to take the quiz or skip this skill and move to the next
                skill?
              </Typography>
              <div>
                <div
                  role="button"
                  onClick={() => {
                    getQuizListApi({
                      skillId: video?.skill?.[0]?.skillId,
                      userId: userData?.id,
                      classroomId:
                        video?.Assignments?.[0]?.assignment?.classroomId,
                    })
                      .then((res) => {
                        if (!res.data) {
                          setFailedQuiz(true);
                        } else {
                          navigate(
                            `/app/student/daily-quiz?skill=${video?.skill?.[0]?.skillId}`,
                          );
                        }
                      })
                      .catch(console.error);
                  }}
                  className="w-[95%] flex bg-[#C73E9D] items-center justify-center text-[#FFFFFF] font-medium py-3 rounded-2xl
text-[15px]"
                >
                  Take me to the quiz
                </div>
                <div
                  role="button"
                  onClick={() => {
                    moveToNextSkill();
                  }}
                  className="w-[95%] flex bg-[#FFFFFF] border border-[#C73E9D] mt-2 items-center justify-center text-[#C73E9D] font-medium py-3 rounded-2xl
text-[15px]"
                >
                  Move to next skill
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default forwardRef(LessonComment);
