import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { incorrect } from 'asset';
import 'katex/dist/katex.min.css';

const obj = {
  a: 'optionA',
  b: 'optionB',
  c: 'optionC',
  d: 'optionD',
};

export default function WrongAnswerReview(props) {
  let { correctAnswer, onClose, replaceRaceNumber, isReview } = props;
  const answer = correctAnswer[obj[correctAnswer.answer.toLocaleLowerCase()]];

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={true}
      onClose={props.onClose}
    >
      <DialogContent>
        <Box textAlign={'center'}>
          <img
            src={incorrect}
            alt="incorrect"
            style={{ margin: 'auto', marginTop: '12px', marginBottom: '12px' }}
          />
          <Box>
            <Typography variant="h5" sx={{ color: '#000', fontWeight: '700' }}>
              Your answer is Incorrect
            </Typography>
            {/* <Typography
              // variant="subtitle1"
              sx={{ color: '#000', fontSize: '14px', mt: 2 }}
            >
              The correct answer is{' '}
              <span>
                <Latex>{answer}</Latex>
              </span>
            </Typography> */}
            {/* {isReview && (
              <Typography
                // variant="subtitle1"
                sx={{ color: '#000', fontSize: '14px', mt: 2 }}
              >
                The correct answer is{' '}
                <span style={{ display: 'contents', wordBreak: 'break-word' }}>
                  {correctAnswer?.explanation || 'Explanation'}
                </span>
              </Typography>
            )} */}
          </Box>
          <Button
            sx={{
              textTransform: 'none',
              width: '60%',
              background: '#FFE5E9',
              color: '#EC495D',
              my: 5,
              '&.MuiButtonBase-root:hover': { backgroundColor: '#FFE5E9' },
            }}
            onClick={onClose}
          >
        Show right answer
            
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
