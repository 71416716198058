import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetMyProfileQuery } from 'store/apis/profile';

function MainHeader({ onClick,text }) {
    const { data: userPersonalData,} = useGetMyProfileQuery();

    useEffect(()=>{
        console.log("data#####", userPersonalData)
    },[])
  return (
    <div className="flex items-center justify-between bg-white mt-2 mx-4 rounded-2xl py-3 px-3">
      <div
        role="button"
        onClick={onClick}
        className="flex items-center "
      >
        <img src="/svg/go_back_icon.svg" alt="arrow-left" />
      </div>
      <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>{text}</Typography>
      <div className="flex py-1 px-2 bg-[#e4f1fd] rounded-full">
        <img src={require('asset/diamond.png')} alt="" className="h-6 w-5" />
        <Typography sx={{ fontSize: '14px', fontWeight: 600, color:"#3AAAFF", marginLeft:1 }}>{" "} ${userPersonalData?.data?.gemsTokens}</Typography>
      </div>
    </div>
  );
}

export default MainHeader;
