import config from 'configs/config';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';

// 'student',
//   'teacher',
//   'parent',
//   'school_leader',
//   'district_leader',
//   'learning_coach',
//   'director_of_technology',
//   'tutor_company',
//   'super_admin',
//   'other',
//   'tutor',
//   'homeschool_parent_esa';

export default function useHandleUserNavigation() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  const navigate = useNavigate();
  const loginUser = (user, token, queries) => {
    const queryParams = queryString.stringify({
      ...queries,
      token,
    });

    switch (user.type) {
      case 'student':
        if (redirectTo === 'codeToEarn') {
          navigate('/app/student/challenge-selection');
          return;
        }
        /*    const isMobileDevice = /Mobi/.test(navigator.userAgent); // Check if 'Mobi' is present in the user agent

        const allowedMobileRoutes = ['/auth/token-login', '/app/student/chat'];

        if (allowedMobileRoutes.includes(window.location.pathname)) {
          return;
        }

        if (isMobileDevice) {
          navigate('/app/student');
          return;
        } */
        navigate('/app/student');
        break;
      case 'teacher':
      case 'private_tutor':
      case 'tutor':
      case 'homeschool_parent_esa':
        localStorage.clear();
        console.log(`${config.ADMIN_URL}/teacher/dashboard?${queryParams}`);
        window.location.href = `${config.ADMIN_URL}/school/${user.school.id}?${queryParams}`;

        break;
      case 'parent':
        localStorage.clear();
        if (
          queries?.redirectTo === 'plans' ||
          queries?.redirectTo === '/plans'
        ) {
          // window.location.href = `${config.USER_URL}/plans`;
          // Linking.openURL(`${config.USER_URL}/plans?${queryParams}`);
          navigate('/plans?redirectTo=parent');
          return;
        }
        window.location.href = `${config.ADMIN_URL}/parent/dashboard?${queryParams}`;
        break;
      case 'school_leader':
      case 'learning_coach':
        localStorage.clear();
        window.location.href = `${config.ADMIN_URL}/school-leader/dashboard?${queryParams}`;
        break;
      default:
        // localStorage.clear();
        // window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?${queryParams}`;
        localStorage.clear();
        window.location.href = `${config.ADMIN_URL}/district-leader/dashboard?${queryParams}`;
        break;
    }
  };

  return { loginUser };
}
