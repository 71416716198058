import { apiSlice } from '..';

export const schoolApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStates: builder.query({
      query: (body) => {
        return {
          url: 'csc/states',
          method: 'GET',
          params: body,
        };
      },
    }),
    getCities: builder.query({
      query: (body) => {
        return {
          url: 'csc/cities',
          method: 'GET',
          params: body,
        };
      },
    }),
    getUserTypes: builder.query({
      query: (body) => {
        return {
          url: '/auth/get-user-types',
          method: 'GET',
          params: body,
        };
      },
    }),
  }),
});

export const { useGetStatesQuery, useGetCitiesQuery, useGetUserTypesQuery } =
  schoolApi;
