import React from 'react';

import { auth } from 'api/firebase';
import animation from 'asset/lottie/bot-animation.json';
import Button from 'components/Button';
import Modal from 'components/Modal/components/Modal';
import useModal from 'components/Modal/hook/useModal';
import useHandleUserNavigation from 'hooks/useHandleUserNavigation';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useGetMyProfileQuery } from 'store/apis/profile';
import getUserName from 'utils/getUserName';

export default function TeacherPaymentWarning() {
  const { closeModal, isOpen, modal } = useModal();
  const { data, isLoading } = useGetMyProfileQuery();
  const { loginUser } = useHandleUserNavigation();

  const navigate = useNavigate();

  const userName = React.useMemo(() => {
    if (data?.data) {
      return getUserName({
        firstName: data?.data?.name,
        lastName: data?.data?.lastname,
        username: data?.data?.username,
        type: data?.data?.type ?? 'student',
      });
    }
    return '';
  }, [data]);

  return (
    <Modal isOpen={isOpen('TeacherPaymentWarning')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_5%]">
        {/* <Modal.CloseButton /> */}
        <div className="flex flex-col gap-4 py-4">
          <Lottie animationData={animation} className="mx-auto w-48 lg:w-56" />

          <div className="grid gap-4">
            <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
              Thank you for completing your profile, {userName}! You’re one step
              closer to helping your kid unlock their learning potential.
            </h1>
            <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
              🎉 You’ve Earned a total of $50 G3MS Tokens! 🎉
            </h1>
            {/* 
            <p className="text-sm md:text-base xl:text-lg  text-black text-center mx-auto max-w-[45ch]">
              Thank you for completing your profile! We’ve added $40 Tokens to
              your wallet. Use these tokens to reward your child with gift
              cards, avatar upgrades, and more!
            </p> */}
          </div>

          <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
            ✨ Turn Tokens into Tangible Rewards with Premium ✨
          </h1>

          <ul className="text-sm xl:text-base text-black space-y-2">
            <li>
              🎁 Redeem tokens for premium gift cards and exclusive rewards.
            </li>
            <li>
              ✏️ Get homework help with our AI Tutor for math, essay writing,
              and coding.
            </li>
            <li>
              📚 Unlock a full library of state-aligned curriculum in ELA,
              Science, and Social Studies.
            </li>
            <li>
              🚀 Prepare your child for the future with workforce skills and
              coding challenges.
            </li>
            <li>
              🎓 Access test prep for high school entrance exams (ISEE, SHSAT,
              SSAT, TACHS) and the SAT/ACT.
            </li>
          </ul>

          <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
            Start Your Free 7-Day Trial Today!
          </h1>
          <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
            Unlock the full G3MS experience with instant access to all Premium
            features. Includes a 7-day free trial!
          </h1>

          <Button
            className="max-w-none w-full"
            variant="primary"
            onClick={async () => {
              closeModal();
              // navigate('/plans');
              const accessToken = await auth.currentUser?.getIdToken();
              loginUser(data?.data, accessToken, {
                redirectTo: '/plans',
              });
            }}
            isLoading={isLoading}
          >
            Try G3MS for Free Today
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
