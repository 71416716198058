import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import EmptyChatTab from './EmptyChatTab';
import AnimatedLogo from '../../../asset/Bot-lottie1-CL-V1.json'
import Lottie from 'lottie-react';
function EmptyChatBox({isborderImage=true,navigate=true,isIcon=true}) {
  return (

<>
  
   <div className="flex h-full">
    <div className=" m-auto">
    <div className='lg:flex justify-center items-end space-y-4 lg:space-y-0 lg:mt-[7%] lg:gap-3'>
    <EmptyChatTab image={require('../../../asset/writing-h.png')} heading="Get Writing Help" detail="Need help structuring your paper? Our AI tutor guides you without writing it for you."></EmptyChatTab>
    <EmptyChatTab image={require('../../../asset/math-h.png')} heading="Ace Your Math Problems" detail="Stuck on a math problem? Get hints and learn step-by-step without just getting the answer."></EmptyChatTab>
    <EmptyChatTab image={require('../../../asset/code-h.png')} heading="Code Like a Pro" detail="Looking for coding help? Copy code snippets to use in our editor to build web apps and pages."></EmptyChatTab>
    </div>
    {/* <div className="-bottom-[1.5%] z-150 absolute flex justify-end lg:-mr-[4%]">
    <Lottie className='w-48' animationData={AnimatedLogo}></Lottie>
    </div> */}


    </div>
 
</div>
   <div className=" flex justify-end -bottom-[2%] -mr-12 ">
   <Lottie className=" h-36"   animationData={AnimatedLogo}></Lottie>
   </div>
   </>


   

  );
}

export default EmptyChatBox;
