import { LoadingOverlay } from '@mantine/core';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import { auth } from 'api/firebase';
import { AppStore, PlayStore } from 'asset';
import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import Cookies from 'js-cookie';
import React from 'react';
import { IoShareSocialOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiSlice } from 'store/apis';
import {
  useGetMyProfileQuery,
  useGetUserQrByIdQuery,
} from 'store/apis/profile';
import { logoutUser } from 'store/Slice/authSlice';

export default function WebRedirect() {
  const { data, isLoading } = useGetMyProfileQuery();

  const {openModal} =useModal()

  // const { data: qrCodeData, isLoading: qrCodeDataLoading } =
  //   useGetUserQrCodeQuery();

  const { data: qrCodeData, isLoading: qrCodeDataLoading } =
    useGetUserQrByIdQuery(data?.data?.id, {
      skip: !data?.data?.id,
    });

  const saveImageToFileSystem = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const handle = await window.showSaveFilePicker({
        suggestedName: `QR-${data?.data?.id}.png`,
        types: [
          { description: 'Images', accept: { 'image/*': ['.jpg', '.png'] } },
        ],
      });

      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
      console.log('Image saved!');
    } catch (error) {
      console.error('Error saving image:', error);
    }
  };

  const handleShare = async (imageUrl) => {
    if (navigator.share) {
      try {
        const blob = await fetch(imageUrl).then((res) => res.blob()); // Convert Base64 to Blob
        const file = new File([blob], 'shared-image.png', {
          type: 'image/png',
        });

        await navigator.share({
          title: 'Check this out!',
          text: 'Here’s an amazing image!',
          url: file, // Must be an online URL, not a local file
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      
      openModal('WarningModal', {
        title: 'Warning',
        description: 'Your browser does not support this feature, do you want to download the image instead?',
        onContinue: () => {
          saveImageToFileSystem(imageUrl);
        }
      });
    }
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logoutUser());
    localStorage.clear();
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    auth.signOut();
    navigate('/');
  };

  const handleDownload = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="">
      <div className="flex justify-between items-center p-4 px-6 bg-white shadow-md sticky top-0 w-full z-10">
        <Logo className="w-20 lg:w-24 xl:w-32 m-0" />

        <IconButton
          onClick={handleLogout}
          sx={{ fontSize: '30px' }}
          aria-label="delete"
        >
          <LogoutIcon sx={{ fontSize: '24px' }} />
        </IconButton>
      </div>
      <AuthPage className="gap-4 content-center p-4">
        <Logo className="w-32 mb-4" />
        <AuthDetails>
          <AuthDetails.Title>Download Our Mobile App</AuthDetails.Title>
          <AuthDetails.Description>
            Looks like you are on mobile device and for the best experience
            click below to download our native iOS or android app.
          </AuthDetails.Description>
        </AuthDetails>

        <div className="grid gap-10">
          <div className="grid grid-cols-2 gap-4">
            <div
              onClick={() =>
                handleDownload(
                  'https://apps.apple.com/us/app/g3ms/id1625313492',
                )
              }
              className="flex flex-col items-center justify-center flex-1 gap-6"
            >
              <img
                src={AppStore}
                alt="Download on the App Store"
                className="size-24"
              />

              <p className="text-center max-w-[16ch] mx-auto text-lg xl:text-xl 2xl:text-2xl">
                Download on the App Store
              </p>
            </div>
            <div
              onClick={() =>
                handleDownload(
                  'https://play.google.com/store/apps/details?id=com.g3ms',
                )
              }
              className="flex flex-col items-center justify-center flex-1 gap-6"
            >
              <img
                src={PlayStore}
                alt="Get it on Google Play"
                className="size-24"
              />

              <p className="text-center max-w-[16ch] mx-auto text-lg xl:text-xl 2xl:text-2xl">
                Download on the App Store
              </p>
            </div>
          </div>

          <div className="border border-gray-[#00000033] rounded-2xl p-4 grid gap-4 w-full">
            <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl font-semibold">
              Student Detail
            </p>
            <div className="w-full">
              <div className="flex gap-4 items-center justify-between">
                <div className="flex gap-4 items-center">
                  <div className="flex flex-col justify-center items-center gap-2">
                    {data?.data?.profileImage ? (
                      <img
                        src={data?.data?.profileImage}
                        alt="Profile"
                        className="size-8 sm:16 rounded-full lg:size-20 xl:size-28 2xl:size-48"
                      />
                    ) : (
                      <div className="size-12 sm:16 rounded-full lg:size-20 xl:size-28 2xl:size-32 grid place-items-center bg-[#F0F0F0] text-[#929292]">
                        <p className="text-sm font-medium xl:text-2xl">
                          {data?.data?.name?.charAt(0).toUpperCase()}{' '}
                          {data?.data?.lastname?.charAt(0).toUpperCase()}
                        </p>
                      </div>
                    )}

                    <p className="text-xs sm:text-sm md:text-base lg:text-xl 2xl:text-2xl text-[#20A58A] max-sm:max-w-[10ch] text-center">
                      {data?.data?.name} {data?.data?.lastname}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-6 gap-y-4 content-start">
                    <div className="">
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl text-[#929292]">
                        Username
                      </p>
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl">
                        {data?.data?.username}
                      </p>
                    </div>
                    <div className="">
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl text-[#929292]">
                        Grade
                      </p>
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl">
                        {data?.data?.grade?.name}
                      </p>
                    </div>
                    <div className="col-span-2">
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl text-[#929292]">
                        School Name
                      </p>
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl">
                        {data?.data?.school?.name}
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  src={qrCodeData?.data?.qr}
                  alt="QR Code"
                  className="w-[4.5rem] h-[4.5rem] sm:w-32 sm:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 2xl:w-56 2xl:h-56"
                />
              </div>
            </div>
          </div>

          <Button
            variant={'primary'}
            className="w-full text-base max-w-full h-16 flex items-center justify-center gap-2"
            onClick={() => handleShare(qrCodeData?.data?.qr)}
            // component="a"
            // href={qrCodeData?.data?.qr}
            // download={`QR-${data?.data?.id}.png`}
          >
            <IoShareSocialOutline
              className="mr-2 text-white group-hover:text-[#C44297]"
              size={24}
            />
            <span>Download & Share</span>
          </Button>
        </div>
      </AuthPage>
      <LoadingOverlay visible={isLoading || qrCodeDataLoading} />
    </div>
  );
}
