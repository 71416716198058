import React, { useEffect } from 'react';

import useSocialSignIn from 'hooks/useSocialSignIn';
import AppleIcon from './assets/apple.png';
import ClassLinkIcon from './assets/classlink.png';
import GoogleIcon from './assets/google.png';

const logos = {
  google: {
    color: '#c94336',
    icon: GoogleIcon,
  },
  apple: {
    color: '#e2143d',
    icon: AppleIcon,
  },
  classlink: {
    color: '#1890d5',
    icon: ClassLinkIcon,
  },
};

const LogoButton = ({ logo, text, onClick }) => {
  return (
    <div className={'flex flex-col items-center gap-2'}>
      <button
        className={'grid place-items-center rounded-md py-4 px-5'}
        onClick={onClick}
        style={{ backgroundColor: logos[logo].color }}
      >
        <div className="bg-white rounded-full p-2">
          <img
            src={logos[logo].icon}
            alt={`${logo} logo icon`}
            className="size-5 md:size:h-6 xl:size:h-7 object-contain aspect-square"
          />
        </div>
      </button>
      <p className="text-sm md:text-base text-black font-medium max-md:hidden">
        Login with <br />
        <span className="text-[#000] font-semibold">{text}</span>
      </p>
    </div>
  );
};

export default function SocialLogin({ setIsLoading, callback }) {
  const { googleSignIn, appleSignIn, classLinkSignIn, isLoading } =
    useSocialSignIn();

  useEffect(() => {
    if (setIsLoading !== undefined) {
      isLoading && setIsLoading(true);
      !isLoading && setIsLoading(false);
    }
  }, [isLoading, setIsLoading]);

  return (
    <div className="grid gap-4 mx-auto flex items-center justify-center text-center">
      <div className="grid grid-cols-[1fr,auto,1fr] gap-4 items-center">
        <hr className="border-[#DCDCDC]" />
        <p className="text-sm md:text-base xl:text-lg text-[#979C9E] font-medium">
          OR
        </p>
        <hr className="border-[#DCDCDC]" />
      </div>
      <div>
        <div className="flex items-center justify-center gap-8">
          <LogoButton
            logo="apple"
            text="Apple ID"
            onClick={() => appleSignIn(callback)}
          />
          <LogoButton
            logo="google"
            text="Google"
            onClick={() => googleSignIn(callback)}
          />
          <LogoButton
            logo="classlink"
            text="Class Link"
            onClick={() => classLinkSignIn(callback)}
          />
        </div>
      </div>
      {/* <LoadingOverlay isLoading={isLoading} /> */}
    </div>
  );
}
